import React from "react";
import SectionsHead from "../Home/Heading";
import "../../style/partials/components/_trams&condition.scss";

const Termsandservices = () => {
  return (
    <div className="container mt-3 mb-5">
      <SectionsHead heading="Terms And Services" />
      <div className="trams_sarvices-div">
        <h3>Important </h3>
        <p>
          Ooze IT Solutions Pvt. Ltd., a company incorporated under the laws of
          India and having its registered office at 59, New Rampura Circle, Near
          BSNL Tower, Udaipur, Rajasthan 313001. (“Company”) has developed a
          mobile community application called “A2Z”
        </p>
        <p>
          These Legal Terms constitute a legally binding agreement made between
          you, whether personally or on behalf of an entity ("you"), and A2Z,
          concerning your access to and use of the Services. You agree that by
          accessing the Services, you have read, understood, and agreed to be
          bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL. OF
          THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE
          SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
        </p>
        <p>
          We will provide you with prior notice of any scheduled changes to the
          Services you are using. The modified Legal Terms will become effective
          upon posting or notifying you by support@a2z.com, as stated in the
          email message. By continuing to use the Services after the effective
          date of any changes, you agree to be bound by the modified terms.
        </p>
        <p>
          You can contact us by phone at <a href="#" className="herf-links">+917976768772</a> or email at{" "}
          <a href="#" className="herf-links">hello@itooze.com</a>, 59, New Rampura Circle Near BSNL Tower Udaipur,
          Rajasthan 313001, Udaipur, Rajasthan 313001, India.
        </p>
        <p>
          The Services are intended for users who are at least 13 years of age.
          All users who are minors in the jurisdiction in which they reside
          (generally under the age of 18) must have the permission of, and be
          directly supervised by, their parent or guardian to use the Services.
          If you are a minor, you must have your parent or guardian read and
          agree to these Legal Terms before you use the Services.
        </p>
        <ol>
          <li>
            <h3>Description of the App</h3>
          </li>
          <p>
            - Welcome to A2Z Counseling, Our cutting-edge platform connects users
            with expert counsellors across various specialities. Experience a
            seamless journey to well-being with transparent reviews, flexible
            payments, and accessible services.
          </p>
          <p>
            <b>Users:</b> Counselees explore a variety of skills with advanced
            search features for their unique needs, spanning career, finance,
            IT, personal growth, and more. Easily find verified, specialized
            counsellors through search and filters. Engage in dynamic session
            bidding, allowing personalised pricing for sessions. Seamlessly pay
            with various methods, schedule sessions on preferred platforms, and
            provide valuable feedback for a counselling experience.
          </p>
          <p>
            <b>Counsellor:</b> Prepare a professional presence with a detailed
            Counselor Profile, showcasing key information, skills, experience,
            and documents. Effortlessly post counselling sessions, including
            session details and pricing, and manage sessions precisely by
            accepting or rejecting bids. Schedule sessions, conduct them on
            preferred platforms, and receive seamless payments into your
            counsellor's wallet. Gather valuable feedback for continuous
            improvement from Counselees at the end of each session.{" "}
          </p>

          <li>
            <h3> Use of the App</h3>
          </li>
          <p>
            - Join A2Z Counseling for a special journey! Our website is designed
            to help with many things that make you feel good. If you want to
            grow, do well in your job, manage money, learn in school, talk about
            relationships, or get help with your feelings, our counsellors are
            ready. They know a lot about different things and can support you.
            A2Z Counseling is like a friend that helps you in many ways for a
            happy and successful life.
          </p>
          <p>
            In your use of the Web, you shall always comply with all applicable
            laws.
          </p>
          <p>
            You shall not take any action that would cause the Company to suffer
            any type of loss.
          </p>

          <li>
            <h3> Account Registration</h3>
          </li>
          <p>
            - To use the Website, you must register for an account. You must
            provide accurate, complete, and up-to-date registration information.
            You are solely responsible for maintaining the confidentiality and
            security of your account and password.
          </p>

          <li>
            <h3> Intellectual Property</h3>
          </li>
          <p>
            The Company and its licensors, if any, shall be the sole and
            absolute owners of the Website, including but not limited to the
            idea behind the Web, the copyright in all content on the Website,
            and all trademarks, designs, logos and other insignia of trade used
            on the Website and elsewhere during the performance of the Services,
            all of which are subject to protection under patent, copyright,
            trademark and trade secret and other intellectual property laws of
            India and other countries. Any copying of any part of the Website
            shall entail immediate termination of these Terms without prejudice
            to the other rights and remedies of the Company, including for civil
            wrongs and criminal offences.
          </p>

          <li>
            <h3> Governing Law</h3>
          </li>
          <p>
            These Terms shall be governed by and construed under the laws of
            “INDIA”. Any disputes arising under or in connection with these
            Terms shall be resolved exclusively in the court.
          </p>

          <li>
            <h3> Changes to the Terms</h3>
          </li>
          <p>
            We reserve the right to modify or update these Terms at any time.
            Your continued use of the Website after any such changes constitutes
            your acceptance of the new Terms. By using the Website, you
            acknowledge that you have read, understood and agree to be bound by
            these Terms. If you do not agree to these Terms, you should not use
            the Website.
          </p>
          <li>
            <h3> Contact Us</h3>
          </li>
          <p>
            To resolve a complaint regarding the Services or to receive further
            information regarding the use of the Services, please contact us at
            A2Z Ooze IT Solutions Pvt. Ltd., 59, New Rampura Circle, Near BSNL
            Tower. Udaipur, Rajasthan 313001. India <a href="#" className="herf-links">support@itooze.com</a>
          </p>
        </ol>
      </div>
    </div>
  );
};

export default Termsandservices;
