import { useContext, useEffect, useState } from "react";
import UserContext from "../../../contexts/UserContext";
import SectionsHead from "../../Home/InnerHeading";
import "../../../style/partials/Counsellor/_dashboard.scss";

const Earnings = (props) => {
  const { userData } = useContext(UserContext);
  const [wallets, setWallets] = useState([]);
  console.log(props);
  useEffect(() => {
    setWallets((prevState) => props.userdetails);
  }, [props.userdetails]);

  //   console.log(wallets);
  return (
    <div className="container">
      {wallets && (
        <div className="row border rounded m-2 border-dark d-flex justify-content-center align-items-center">
          <SectionsHead heading="Balance" />
          {wallets.map((item, index) => (
            <div className="col-11 col-lg-3 col-md-4  p-1 border rounded m-2 border-dark ">
              <div className="d-flex justify-content-between ">
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "18px",
                    color: "#2a578d",
                    textAlign: "left",
                  }}
                >
                  Currency :
                </label>
                <span style={{ textAlign: "right" }}>{item.currencyCode}</span>
              </div>
              <div className="d-flex justify-content-between ">
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "18px",
                    color: "#2a578d",
                  }}
                >
                  Balance
                </label>
                <span>{item.balance}</span>
              </div>
              <div className="d-flex justify-content-between">
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: "18px",
                    color: "#2a578d",
                  }}
                >
                  Last Updated :
                </label>

                <span>{new Date(item.createdAt).toDateString()}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default Earnings;
