import React, { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../../api-config";
import SectionsHead from "../Home/Heading";
import "../../style/partials/components/_reviewsAll.scss";
import profile1 from "../../images/man (2)@3x.png";
import { Rating } from "react-simple-star-rating";

const Reviews = () => {
  const [reviewsList, setReviewsList] = useState([]);
  const getTopReviews = async () => {
    const topReviews = await axios.get(`${API}/api/feedback/all-reviews`);
    // console.log("dfvfdvdvsdvsvs", topReviews.data);
    setReviewsList(topReviews.data);
  };
  useEffect(() => {
    getTopReviews();
  }, []);
  return (
    <div className="mt-5 mb-5">
      <div className="fluid">
        <SectionsHead heading="Counsellor Reviews" />
      </div>
      <section className="reviews-section-teacher">
        <div className="container">
          <div className="col-lg-10">
            <h2>Teacher Reviews</h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </p>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          {reviewsList &&
            reviewsList.map((item, index) => {
              return (
                <div className="col-xl-3 col-lg-4 col-md-6 mb-4">
                  <div className="reviews-sec-card h-100 shadow">
                    <div className="row">
                      <div className="col-12 d-flex">
                        <div className="img-titel-div2 col-12">
                          <img
                            src={profile1}
                            className=" col-3 card-img prof-img"
                            alt=""
                          />
                          <div className="col-9 name-ditels">
                            <p>
                              <span className="teacher-name">
                                {item && item.counsellorId.fName}
                              </span>
                            </p>
                            <div className="star-reating">
                              <Rating
                                className=""
                                readonly={true}
                                initialValue={item.counsellorRating}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body body-summery">
                      <p className="card-text">
                        {`${item.feedbackDesc}`} Lorem Ipsum is simply dummy
                        text of the printing and typesetting industry. Lorem
                        Ipsum has been the industry's standard dummy text ever
                        since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Reviews);
