import React, { useState, useEffect, useContext } from "react";
import "../../../style/partials/components/_event.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { API } from "../../../api-config.js";
import axios from "axios";
import "alertifyjs/build/css/alertify.css";
import CounsellorHome from "../Home/Home";
import socket from "../../../socket/socketio";
import UserContext from "../../../contexts/UserContext";

let data = [];
function Eventcreate() {
  const {
    register,
    formState: { errors },
    trigger,
    handleSubmit,
    clearErrors,
    reset,
  } = useForm();
  const { userData } = useContext(UserContext);
  const [num, setNum] = useState(0);
  let message = "";
  const navigate = useNavigate();
  const locData = useLocation();
  console.log("USERDATA", userData, "LOCDATA", locData);
  const submit = async () => {
    const formData = new FormData(document.getElementById("form"));
    let sessionDetails = {
      sessionTitle: formData.get("sessiontitle"),
      startDate: formData.get("sessiondate"),
      fromHour: formData.get("starttime"),
      // toHour: formData.get("starttime").addHours(1),
      sesDes: formData.get("sessiondes"),
      cost: locData.state.sessions[num].Price,
      requestPay: false,
      paid: false,
    };
    data.push(sessionDetails);
    setNum(num + 1);
    reset();
    if (num + 1 == locData.state.state.noOfSessions) {
      message = `${userData.fName} ${userData.lName} scheduled ${locData.state.state.noOfSessions} counsellings on ${locData.state.state.sessiontitle}. Please Check.`;
      console.log("EMIT LOCDATA");

      let userid = {
        _id: locData.state.state.userId,
      };
      let notificationDetails = {
        msg: message,
        entity: "Session",
      };
      const notificationResponse = await axios.post(
        `${API}/api/notifications/update`,
        { details: notificationDetails, userData: userid },
        {
          headers: {
            token: userData.token,
          },
        }
      );
      socket.emit("noti", {
        msg: message,
        _id: locData.state.state.userId,
      });
      socket.emit("sessionDecision", {
        msg: message,
        _id: locData.state.state.userId,
      });
      const response = await axios.post(
        `${API}/api/upcomingSession/create-upcoming`,
        {
          details: data,
          sessionInfo: { ...locData.state },
          leftBal: locData.state.leftBal,
        },
        {
          headers: {
            token: userData.token,
          },
        }
      );
      console.log("LOCDATA", locData);
      data = [];
      navigate("/");
    }
  };
  //I need here number of session provied by who to whom at which topic at what date and time and how many sessions
  return (
    <>
      {num < locData.state.state.noOfSessions ? (
        <section className="event-form">
          <div className="contianer meet-session-form ">
            <div className="session-box p-3">
              <div className="session-heading mb-3 text-center ">
                <h6 className="fw-bolder">Schedule Session Slot {num + 1}</h6>
              </div>
              <form
                id="form"
                onSubmit={handleSubmit(submit)}
                className="text-center"
              >
                <div className="form-list mb-2 ">
                  <input
                    id="sessionName"
                    name="sessionName"
                    type="text"
                    placeholder="Enter Session Name"
                    value={locData.state.state.sessiontitle}
                    {...register("sessiontitle", { required: true })}
                  />
                  {errors.sessiontitle && (
                    <p className="valid-proof">This field is required</p>
                  )}
                </div>
                <div className="form-list mb-2">
                  <input
                    id="date"
                    name="date"
                    type="date"
                    min={new Date().toISOString().slice(0, 10)}
                    placeholder="Enter Session Date (MM/DD/YYYY)"
                    {...register("sessiondate", { required: true })}
                  />
                  {errors.sessiondate && (
                    <p className="valid-proof">This field is required</p>
                  )}
                </div>
                <div className="form-list mb-2">
                  <input
                    id="date"
                    name="date"
                    type="time"
                    placeholder="Enter Session Start Time (MM/DD/YYYY)"
                    {...register("starttime", { required: true })}
                  />
                  {errors.starttime && (
                    <p className="valid-proof">This field is required</p>
                  )}
                </div>
                {/* <div className="form-list mb-2 ">
                  <input
                    id="date"
                    name="date"
                    type="time"
                    placeholder="Enter Session End Time (MM/DD/YYYY)"
                    {...register("endtime", { required: true })}
                  />
                  {errors.endtime && (
                    <p className="valid-proof">This field is required</p>
                  )}
                </div> */}
                <div className="form-list mb-2 ">
                  <textarea
                    name="description"
                    type="text"
                    id="sessionDescp"
                    placeholder="Enter Session Description"
                    {...register("sessiondes", { required: true })}
                  />
                  {errors.sessiondes && (
                    <p className="valid-proof">This field is required</p>
                  )}
                </div>
                <button type="submit" id="session-sub-btn" className="w-bolder">
                  SUBMIT
                </button>
              </form>
            </div>
          </div>
        </section>
      ) : (
        <CounsellorHome />
      )}
    </>
  );
}

export default React.memo(Eventcreate);

//DATA USED ABOVE
//USER DATA FROM USER CONTEXT
// auth
// :
// true
// defaultCurrency
// :
// "EUR"
// fName
// :
// "Arpit G"
// flag
// :
// "COUNSELLOR"
// isProfile
// :
// true
// lName
// :
// "Gaurav G"
// refreshToken
// :
// "IkMEBG7au6GhRNAjKNjcPFRM3pYdty97W6EqLkCDYKXbErJ07TGkKlo4b13f6JlRF4Y9E8UYMRaP6osu4Sc3Eyvqu7EtbOGR5RWiQJpyLwUycRCQsYqAsx49RgfHIf95TLfuGAPoQv990JqEWxhISktYolIkNTqslAPoX9bx7QRgaELUw3gLao6vhxZTCtWtoDgZdgFrtMbiuSZtXQznV59EdiCqyNiuqPgs3yI967L0Jscf41BAiHQF4pHJvd0x"
// token
// :
// "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1Yzc1MzRjMGFhMzAyN2U4NWRiODRhYSIsImlhdCI6MTcwNzU2NjExNiwiZXhwIjoxNzA3NTY2NDE2fQ.8bDRzTKgTgQaEaOQzGxx2ZPGmPaPfuqk7JbWbOfLXQ8"
// userId
// :
// "65c7534c0aa3027e85db84aa"

//Data came from confirmation page EMP
// state
// :
// leftBal
// :
// 1110
// sessions
// :
// Array(1)
// 0
// :
// Price
// :
// "1110"
// PriceCheck
// :
// ""
// description
// :
// "S1"
// sessions
// :
// "Slot 1"
// [[Prototype]]
// :
// Object
// length
// :
// 1
// [[Prototype]]
// :
// Array(0)
// state
// :
// bidId
// :
// "65c77a9ab6a8c84f51e471d0" //BIDID
// counsellorId
// :
// "65c7534c0aa3027e85db84aa" //COUNSELLOR ID
// noOfSessions
// :
// "1"
// sessionDes
// :
// "efewf"
// sessionDetails
// :
// Array(1)
// 0
// :
// Price
// :
// "1110" //leftBal
// PriceCheck
// :
// ""
// description
// :
// "S1"
// paid
// :
// false
// requestPay
// :
// false
// sessions
// :
// "Slot 1"
// [[Prototype]]
// :
// Object
// length
// :
// 1
// [[Prototype]]
// :
// Array(0)
// sessionId
// :
// "65c7646e5163d12aad0722ab" //SESSION ID
// sessiontitle
// :
// "PHP"
// skills
// :
// Array(1)
// 0
// :
// "65b789e02d7d7d746f8b849f"  //SKill iD FOR SESSIOn
// length
// :
// 1
// [[Prototype]]
// :
// Array(0)
// userId
// :
// "65c753c80aa3027e85db84e6" // STUDENT ID
