import React, { useState, useRef, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import SectionsHead from "../../Home/Heading";
import { API } from "../../../api-config";
import axios from "axios";
import useOnClickOutside from "../../../hooks/use-onclick-outside";
// import { throttle } from "lodash";
import { ImUserPlus } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import "../../../style/partials/Students/_userpro.scss";
import UserContext from "../../../contexts/UserContext";
import { useForm } from "react-hook-form";

let wait = false;
function UserProfile() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [state, setstate] = useState([]);
  const [country, setcountry] = useState([]);
  const [stu_address, setstu_address] = useState("");
  // const [stu_desr, setstu_desr] = useState("");
  const [docfile, setdocfile] = useState("");
  const [countryid, setcountryid] = useState("");
  const [statename, setstatename] = useState("");
  const [photo123, setphoto123] = useState(null);
  const [isDisabled, setDisabled] = useState(false);
  const [education, setEducation] = useState("");

  const [show, setShow] = useState(true); // trigger
  //
  const { userData, setUserData } = useContext(UserContext);

  const handleInputChange = (event) => {
    //console.log(check)
    setphoto123(URL.createObjectURL(event.target.files[0]));
  };
  const ref = useRef();
  // useOnClickOutside(ref, () => setShow(true));
  const navigate = useNavigate();
  const userid = userData.userId;
  const submitData = (data) => {
    setDisabled(true);
    data.userId = userid;
    data.country = countryid;
    data.state = statename;
    data.address = stu_address;
    data.profilePic = photo123;
    data.Education = document.getElementById("education").value;
    setShow(false);
    axios
      .post(`${API}/api/user-profile/create-profile`, data)
      .then((response) => {
        try {
          axios
            .put(`${API}/api/frontregi/update/${userid}`, {
              isProfile: true,
            })
            .then((res) => {
              userData.isProfile = true;
              setUserData(userData);
              localStorage.setItem("userData", JSON.stringify(userData));
              axios
                .get(`${API}/api/currency/getCurrency/${countryid}`)
                .then((res) => {
                  console.log("RREESS ", res);
                  axios
                    .post(`${API}/api/frontregi/defaultCurrency`, {
                      userId: userData.userId,
                      currency: res.data[0].currency,
                    })
                    .then((res) => {
                      console.log("RESPONSE PROFILE", res);
                      userData.defaultCurrency = res.data.defaultCurrency;
                      setUserData(userData);
                      console.log(res);
                      navigate("/");
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                });
            });
        } catch (error) {
          console.log(error);
        }
      });
  };

  useEffect(() => {
    const getcountry = async () => {
      let myData = await fetch(`${API}/api/country/get-Country`);
      let coutrydata = await myData.json();
      setcountry(await coutrydata);
    };
    getcountry();
    userData.isProfile ? setShow(false) : setShow(true);
  }, []);

  // const throttleSubmitData = throttle(submitData, 2000);
  const handlestate = (event) => {
    const getcountryid = event.target.value;
    console.log(getcountryid, "master");
    setstatename(getcountryid);
  };

  const handlecountry = (event) => {
    const [_id, countryName] = event.target.value.split("_");
    getstate(_id);
    setcountryid(countryName);
  };

  const getstate = async (_id) => {
    let myData = await axios.post(`${API}/api/state/get-statebycountry`, {
      countryId: _id,
    });
    setstate(myData.data);
  };

  return (
    <>
      {!userData.isProfile ? (
        <div ref={ref}>
          {` `}
          <div className="pl-0">
            <section className="studentprofile-pop container">
              <div className="modal-content  tech-resignation" id="modalheader">
                <form
                  onSubmit={handleSubmit((data) => {
                    if (wait) {
                      return;
                    }
                    wait = true;
                    setTimeout(() => {
                      wait = false;
                    }, 5000);
                    submitData(data);
                  })}
                  enctype="multipart/form-data"
                >
                  <div className="row profile-and-ditels">
                    <div className="image-profile-main-div col-lg-4">
                      <div className="modal-header profile-box mt-1  ">
                        <div
                          className="modal-title text-center profile m-auto"
                          id="exampleModalLabel"
                        >
                          <div
                            for="photo-upload"
                            className="custom-file-upload fas"
                          >
                            <div className="img-wrap img-upload">
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                version="1.1"
                                viewBox="0 0 16 16"
                                className="up-icn"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M6 11.5c0-2.363 1.498-4.383 3.594-5.159 0.254-0.571 0.406-1.206 0.406-1.841 0-2.485 0-4.5-3-4.5s-3 2.015-3 4.5c0 1.548 0.898 3.095 2 3.716v0.825c-3.392 0.277-6 1.944-6 3.959h6.208c-0.135-0.477-0.208-0.98-0.208-1.5z"></path>
                                <path d="M11.5 7c-2.485 0-4.5 2.015-4.5 4.5s2.015 4.5 4.5 4.5c2.485 0 4.5-2.015 4.5-4.5s-2.015-4.5-4.5-4.5zM14 12h-2v2h-1v-2h-2v-1h2v-2h1v2h2v1z"></path>
                              </svg>
                            </div>
                            <input
                              id="photo-upload"
                              type="file"
                              name="image"
                              accept="image/*"
                              wfd-id="id4"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="modal-header mt-1 col-lg-4">
                    <div
                      className="modal-title text-center profile m-auto  "
                      id="exampleModalLabel"
                    >
                      <label
                        for="photo-upload"
                        className="custom-file-upload fas"
                      >
                        <div className="img-wrap img-upload">
                          {photo123 ? (
                            <img
                              for="photo-upload"
                              src={photo123}
                              alt="upload"
                            />
                          ) : (
                            <ImUserPlus className="up-icn" />
                          )}
                        </div>
                        <input
                          id="photo-upload"
                          name="profilePic"
                          type="file"
                          onChange={handleInputChange}
                        />
                      </label>
                    </div>
                  </div> */}
                    <div className="col-lg-6 modal-body-div">
                      <input
                        className="user-name-counsellor"
                        type="text"
                        placeholder="Full Name"
                        value={
                          userData.fName.charAt(0).toUpperCase() +
                          userData.fName.slice(1) +
                          " " +
                          userData.lName.charAt(0).toUpperCase() +
                          userData.lName.slice(1)
                        }
                      />

                      <select
                        className="custom-select"
                        id="education"
                        defaultValue=""
                        name="company"
                        {...register("company", { required: true })}
                      >
                        <option
                          value=""
                          disabled
                          onChange={(e) => {
                            setEducation(e.target.value);
                          }}
                        >
                          Select Education
                        </option>
                        <option value="10th">10th</option>
                        <option value="12th">12th</option>
                        <option value="B.tech">Graduate</option>
                        <option value="M.tech">Post-Graduate </option>
                      </select>
                      {errors.company && (
                        <span className="valid-prof">
                          This field is required
                        </span>
                      )}
                      <input
                        type="number"
                        placeholder="Alternate Contact Number"
                        maxLength={13}
                        onKeyDown={(e) => {
                          console.log(e.key, " ,", e.keyCode);
                          if (
                            e.keyCode == 38 ||
                            e.keyCode == 37 ||
                            e.keyCode == 39 ||
                            e.keyCode == 40
                          ) {
                            console.log("NOT ALLOWED");
                            e.preventDefault();
                          }
                        }}
                        onWheel={(e) => {
                          e.preventDefault();
                        }}
                        {...register("phone1", {
                          required: true,
                          minLength: 10,
                          maxLength: 13,
                          pattern:
                            /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
                        })}
                      />
                      {errors?.phone1?.type === "required" && (
                        <p className="valid-prof">This field is required</p>
                      )}
                      {errors?.phone1?.type === "pattern" && (
                        <p className="valid-prof">
                          Insert Valid Contact Number
                        </p>
                      )}
                      {errors?.phone1?.type === "minLength" && (
                        <p className="valid-prof">Insert Valid Contact</p>
                      )}
                      {errors?.phone1?.type === "maxLength" && (
                        <p className="valid-prof">Insert Valid Contact</p>
                      )}
                    </div>
                    <div className="col-md-4">
                      <textarea
                        type="text"
                        {...register("addr", {
                          required: true,
                          maxLength: 100,
                        })}
                        id="stu_address"
                        value={stu_address}
                        onChange={(e) => setstu_address(e.target.value)}
                        placeholder="Address"
                      />
                      {errors?.addr?.type === "required" && (
                        <p className="valid-prof">This field is required</p>
                      )}

                      <select onChange={(e) => handlecountry(e)}>
                        <option defaultValue hidden>
                          {"Select Country"}
                        </option>
                        {country.map((getcon, index) => {
                          return (
                            <>
                              <option
                                key={index}
                                value={
                                  getcon._id + "_" + getcon.countryName + "_"
                                }
                              >
                                {getcon.countryName}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-4 modal-body-div">
                      <select onChange={(e) => handlestate(e)}>
                        <option defaultValue hidden>
                          {"Select State"}
                        </option>
                        {state.map((getste, index) => {
                          return (
                            <>
                              <option key={index} value={getste.stateName}>
                                {getste.stateName}
                              </option>
                            </>
                          );
                        })}
                      </select>
                      <input
                        placeholder="City"
                        onKeyPress={(e) => {
                          if (e.target.value === "" && e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        {...register("city", {
                          required: true,
                          maxLength: 20,
                          pattern: /^[A-Za-z]+$/i,
                        })}
                      />
                      {errors?.city?.type === "required" && (
                        <p className="valid-prof">This field is required</p>
                      )}

                      {errors?.city?.type === "maxLength" && (
                        <p className="valid-prof">City name is too long</p>
                      )}
                    </div>
                    <div className="col-md-4 ">
                      <input
                        type="number"
                        onKeyDown={(e) => {
                          console.log(e.key, " ,", e.keyCode);
                          if (
                            e.keyCode == 38 ||
                            e.keyCode == 37 ||
                            e.keyCode == 39 ||
                            e.keyCode == 40
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onWheel={(e) => {
                          e.preventDefault();
                        }}
                        onKeyPress={(e) => {
                          if (e.target.value === "" && e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        placeholder="Zip Code"
                        {...register("zip", {
                          required: true,
                          maxLength: 8,
                        })}
                      />
                      {errors?.zip?.type === "required" && (
                        <p className="valid-prof">This field is required</p>
                      )}
                      {errors?.zip?.type === "maxLength" && (
                        <p style={{ color: "red" }}>
                          Enter valid pincode. Max 8 digits allowed
                        </p>
                      )}

                      <div className="upl-btn mb-3">
                        <label>Upload Your Documents</label>
                        <input
                          type="file"
                          id="doument"
                          value={docfile}
                          onChange={(e) => setdocfile(e.target.value)}
                          placeholder="Choose File"
                        />
                      </div>
                    </div>

                    <div className="modal-body-div "></div>
                  </div>
                  <div className="submit-btn text-center mb-2 mt-2">
                    <button className=" btn btn-submit" disabled={isDisabled}>
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
export default UserProfile;
