import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SectionsHead from "../../Home/Heading";



function Counsellorskills(){
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    return(
        <>
          <SectionsHead heading="How to work with skills" />
          <div className="container mt-4 mb-5">
        <Accordion className="acor-abut" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary className="acor-summ"
          expandIcon={<ExpandMoreIcon className="acor-ico" />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className="acor-titl" sx={{ flexShrink: 0 }} component="span">
            General settings
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="footer-link" component="span">
            Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat.
            Aliquam eget maximus est, id dignissim quam.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="acor-abut" expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary className="acor-summ"
          expandIcon={<ExpandMoreIcon className="acor-ico" />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className="acor-titl" sx={{ flexShrink: 0 }} component="span">Users</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="footer-link" component="span">
            Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus,
            varius pulvinar diam eros in elit. Pellentesque convallis laoreet
            laoreet.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="acor-abut" expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary className="acor-summ"
          expandIcon={<ExpandMoreIcon className="acor-ico" />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className="acor-titl" sx={{ flexShrink: 0 }} component="span">
            Advanced settings
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="footer-link" component="span">
            Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
            amet egestas eros, vitae egestas augue. Duis vel est augue.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="acor-abut" expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary className="acor-summ"
          expandIcon={<ExpandMoreIcon className="acor-ico" />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography className="acor-titl" sx={{ flexShrink: 0 }} component="span">Personal data</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="footer-link">
            Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit
            amet egestas eros, vitae egestas augue. Duis vel est augue.
          </Typography>
        </AccordionDetails>
      </Accordion>
      </div>
        </>
    )
}
export default Counsellorskills;
