import React, { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../../../api-config";
import { useLocation } from "react-router-dom";
import "../../../style/partials/components/_Products.scss";
import CS from "../../../images/NODEJS.jpg";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import SectionsHead from "../../Home/Heading";

const ProductsSession = () => {
  const emp = useLocation();
  const navigate = useNavigate();
  console.log("CATEGORY FOR SESSIONS EMP", emp);
  const [subcategories, setSubCategories] = useState([]);
  const [sessions, setSessions] = useState([]);

  const loadSessions = async () => {
    try {
      const response = await axios.post(`${API}/api/session/products`, {
        category: emp.state,
      });
      // Access the 'data' property of the Axios response to get the subcategories
      setSubCategories(response.data.subcategories);
      setSessions((prevState) => response.data.session);
      console.log("SESSIONS TO BE SHOWN", response.data);
    } catch (error) {
      console.error("Error loading sessions:", error);
    }
  };
  const loadSubsSessions = async (id) => {
    try {
      const response = await axios.post(`${API}/api/session/subproducts`, {
        subcategory: id,
      });
      // Access the 'data' property of the Axios response to get the subcategories
      setSessions((prevState) => response.data.session);
      console.log("SESSIONS TO BE SHOWN", response.data);
    } catch (error) {
      console.error("Error loading sessions:", error);
    }
  };

  useEffect(() => {
    loadSessions();
  }, []); // Make sure to pass an empty dependency array to useEffect to run the effect only once

  return (
    <div className="container">
      <SectionsHead heading="SUBCATEGORIES" />
      <div className="row">
        {subcategories &&
          subcategories?.map((subcategory, index) => (
            <div
              className="col-lg-3 col-md-4 col-sm-12"
              onClick={() => {
                loadSubsSessions(subcategory._id);
              }}
            >
              <div className="ag-courses_item">
                <a href="#" className="ag-courses-item_link">
                  <div className="ag-courses-item_bg"></div>
                  <div className="ag-courses-item_title">
                    {subcategory.name}
                  </div>
                </a>
              </div>
            </div>
          ))}
      </div>
      <div className="container">
        <SectionsHead heading="Top Sessions You Are Looking For" />
        <p className="container-title"></p>
        <div style={{ color: "black", alignItems: "center" }}></div>
        {sessions.length > 0 ? ( 
          <div className="gradient-cards row justify-content-sm-center justify-content-md-start ">
            {sessions &&
              sessions.map((items) => (
                <div className="my-custom-styles col-lg-4 col-xl-3 col-md-6 col-sm-8  ">
                  <div className="session-card-div shadow">
                    <a href="/" className="hero-image-container">
                      <img
                        className="hero-image"
                        src={CS}
                        alt="Spinning glass cube"
                      />
                    </a>
                    <main className="main-content">
                      <div className="d-block">
                        <p className="fw-bold tittel">{items.sessiontitle}</p>
                        <label>Author : </label>
                        <span className="ms-auto"> {`${items?.counsellorId?.fName}`}</span>
                      </div>
                      <div className="flex-row skills">
                        <div className="time-left flex-wrap flex-start">
                          <label className="">Skills :</label>
                          {items?.skills?.slice(0, 3).map((ele) => (
                           <div className="skill-name"><p>{ele.skill} ,</p></div>
                          ))}
                        </div>
                      </div>
                      <div className="description">
                        <label>Description</label>
                         <p className="textdesc"> {items?.sessionDes}</p>
                      </div>
                      <div className="flex ">
                        <div className="coin-base justify-content-between">
                          <div>
                          <label>Cost : </label>
                          <span>
                            {items.sessionCost + " " + items.defaultCurrency}
                          </span> 
                          </div>                          
                          <div className="book-session">
                            <button
                              className="booking"
                              onClick={() => {
                                navigate("/Session-details", {
                                  state: {
                                    id: items._id,
                                    bid: items.sessionNumber,
                                    cost: items.sessionCost,
                                    defaultCurrency: items.defaultCurrency
                                      ? items.defaultCurrency
                                      : "INR",
                                  },
                                });
                              }}
                            >
                              Book Session
                            </button>
                          </div>
                        </div>
                      </div>
                    </main>
                    {/* <div className="card-attribute">
                      <img
                        src="https://i.postimg.cc/SQBzNQf1/image-avatar.png"
                        alt="avatar"
                        className="small-avatar"
                      />
                      <p>
                        Creation of{" "}
                        <span>
                          <a href="#">{items?.counsellorId?.fName}</a>
                        </span>
                      </p>
                    </div> */}
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <h4>No sessions available in this domain</h4>
        )}
      </div>
    </div>
  );
};

export default ProductsSession;
