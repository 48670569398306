import React, { useContext } from "react";
import UserProfile from "../Popmodel/Userprofile";
import Studentbanner from "../Banner/Studentbanner";
import Student from "./Student";
import TipSuccess from "./Tipsuccess";
import Aboutcounselling from "./Aboutcounselling";
import UserContext from "../../../contexts/UserContext";
import Login from "../../form/Login";

function Studenthome() {
  const { userData, setUserData } = useContext(UserContext);

  if (
    userData.flag === undefined ||
    userData.flag === "COUNSELLOR" ||
    userData.token === null
  ) {
    return <Login setToken={setUserData} />;
  }

  return (
    <>
      {!userData.isProfile && <UserProfile />}
      {userData.isProfile && <Studentbanner />}
      {userData.isProfile && <Student />}
      {userData.isProfile && <Aboutcounselling />}
    </>
  );
}

export default Studenthome;
