import React, { Suspense, useContext, lazy } from "react";
import UserContext from "../../contexts/UserContext";
const Loader = lazy(() => import("../Loader/Loader"));
const Silder = lazy(() => import("../sliders/Slider"));
const FeedBacks = lazy(() => import("./Review"));
const Bottom = lazy(() => import("./Bottom"));
const SectionsHead = lazy(() => import("./Heading"));
const HowItWorksSect = lazy(() => import("../HowItWorks/HowItWorksSect"));
const Skillslider = lazy(() => import("../sliders/SkillsSlider"));
const WhyChooseUs = lazy(() => import("../WhyChooseUs/WhyChooseUs"));
const StuFAQs = lazy(() => import("../Student/Home/Aboutcounselling"));
const HomeFAQs = lazy(() => import("../Home/HomeFAQ"));
const CouFAQs = lazy(() => import("../Counsellor/Home/AboutSession"));
const CategorySection = lazy(() => import("../sliders/CategorySection"));
const Advantages = lazy(() => import("./Advantages"));

const Counsellorslider = React.lazy(() =>
  import("../sliders/Counsellorslider")
);

function Home() {
  // debugger;
  const { userData } = useContext(UserContext);
  // console.log(userData);
  return (
    <>
      <main>
        <Silder />
        <Skillslider />
        <Advantages />
        <SectionsHead heading="CATEGORIES" />
        <CategorySection />
        <WhyChooseUs />
        <SectionsHead heading="Our Top Counsellor" />
        <Suspense fallback={<Loader />}>
          <Counsellorslider />
        </Suspense>
        <HowItWorksSect />
        <FeedBacks />
        <HomeFAQs />
      </main>
    </>
  );
}
export default Home;
