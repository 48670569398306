import React, { useState, useEffect, useContext, Suspense, lazy } from "react";
import "../..//..//style/partials/Students/Home.scss";
import first from "../..//../images/img1.png";
import second from "../..//../images/img2.png";
import third from "../..//../images/img3.png";
import SectionsHead from "../../Home/Heading";
import { API } from "../../../api-config";
import UserContext from "../../../contexts/UserContext";
import axios from "axios";
import Loader from "../../Loader/Loader";
import { useLocation } from "react-router-dom";
const Myproposal = lazy(() => import("../Studentsession/Myproposal"));

function Student() {
  const emp = useLocation();

  const [biddata, setbiddata] = useState("");
  const { userData } = useContext(UserContext);

  useEffect(() => {
    const updateUsers = async () => {
      let myData = await axios.post(`${API}/api/bidrequest/get-bid-request`, {
        userId: userData.userId,
      });
      setbiddata(myData.data);
    };
    updateUsers();
  }, []);
  // console.log(biddata,"test")

  if (biddata.length === 0) {
    return (
      <>
        {/* <SectionsHead heading="How to get started" />
        <section>
          <div className="container  pb-4 student-guide">
            <div className="row mt-4 ">
              <div className="col-lg-12 sec-1 justify-content-center">
                <img src={first} alt="" />
                <p>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Incidunt, consequuntur nulla quae optio sed velit fugiat
                  voluptate.
                </p>
              </div>
              <div className="col-lg-12 sec-1 justify-content-center">
                <img src={second} alt="" />
                <p>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Incidunt, consequuntur nulla quae optio sed velit fugiat
                  voluptate.
                </p>
              </div>
              <div className="col-lg-12 sec-1 justify-content-center">
                <img src={third} alt="" />
                <p>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Incidunt, consequuntur nulla quae optio sed velit fugiat
                  voluptate.
                </p>
              </div>
            </div>
          </div>
        </section> */}
      </>
    );
  } else {
    return (
      <>
        <Suspense fallback={<Loader />}>
          <Myproposal />
        </Suspense>

        {/* <SectionsHead heading="How to get started" />
        <section>
          <div className="container pb-4 student-guide">
            <div className="row mt-4 ">
              <div className="col-lg-12 sec-1 justify-content-center">
                <img src={first} alt="" />
                <p>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Incidunt, consequuntur nulla quae optio sed velit fugiat
                  voluptate.
                </p>
              </div>
              <div className="col-lg-12 sec-1 justify-content-center">
                <img src={second} alt="" />
                <p>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Incidunt, consequuntur nulla quae optio sed velit fugiat
                  voluptate.
                </p>
              </div>
              <div className="col-lg-12 sec-1 justify-content-center">
                <img src={third} alt="" />
                <p>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Incidunt, consequuntur nulla quae optio sed velit fugiat
                  voluptate.
                </p>
              </div>
            </div>
          </div>
        </section> */}
      </>
    );
  }
}
export default Student;
