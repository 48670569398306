import React, { useEffect, useState } from "react";
import SectionsHead from "../Home/Heading";
import axios from "axios";
import { API } from "../../api-config";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import imgg from "../../images/ARTIFICIALINTELLIGENCE.jpg";

const Topskills = () => {
  const [skills, setSkills] = useState();
  const navigate = useNavigate();
  const loadPopularSkills = async () => {
    const { data } = await axios.get(`${API}/api/skills/top-skills`);
    console.log(data.allSkills);
    setSkills(data.allSkills);
  };

  const skillsSessions = (skill) => {
    console.log(skill);
    navigate("/popular-skillsessions", {
      state: { entity: "skill", skill: skill },
    });
  };
  useEffect(() => {
    loadPopularSkills();
  }, []);
  return (
    <div className="container">
      <div className="row p-4">
        <SectionsHead heading="Top Skills" />
        {skills &&
          skills.map((item) => (
            <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
              <Card style={{ width: "18rem" }}>
                <Card.Img variant="top" src={imgg} />
                <Card.Body>
                  <Card.Title>{item.skill}</Card.Title>
                  <Card.Text>
                    {item.skill} is a popular skill with most number of
                    bookings.
                  </Card.Text>
                  <Button
                    onClick={() => {
                      console.log(item);
                      skillsSessions(item.skill);
                    }}
                    variant="dark"
                  >
                    Check Available Session
                  </Button>
                </Card.Body>
              </Card>
            </div>
          ))}
      </div>
    </div>
  );
};
export default Topskills;
