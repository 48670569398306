import React, { useEffect, useState, useContext } from "react";
import { API } from "../../../api-config";
import axios from "axios";
import SectionsHead from "../../Home/Heading";
import "..//..//..//style/partials/Students/_sessiondetails.scss";
import Loader from "../../Loader/Loader";
import UserContext from "../../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useLocation } from "react-router-dom";
import useRazorpay from "react-razorpay";
import socket from "../../../socket/socketio";
import CurrencyConverter from "../../CurrencyConversion/CurrencyConverter";
import Freecurrencyapi from "@everapi/freecurrencyapi-js";
import { Modal } from "react-bootstrap";

let wait = false;
let walletBalance = 0;
let sufficient = "";
let amountToBeAdded = 0;
function Sessiondetails() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const freecurrencyapi = new Freecurrencyapi(
    "fca_live_fEt0c5zLpUJY56Orw74x65CnEikODFllCT8mcAK2"
  );
  const { tolAmount } = CurrencyConverter();
  const navigate = useNavigate();
  const [Razorpay] = useRazorpay();
  const { userData } = useContext(UserContext);
  const [radioValue, setRadioValue] = useState("");
  const [isDisabled, setDisabled] = useState(false);
  const [walletData, setWalletData] = useState();
  const [required, setRequired] = useState(0);
  const [users, setUsers] = useState([]);
  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState("");
  const [isPaymentDone, setPayment] = useState(false);

  let emp = useLocation();
  let defaultToSessionCurrency = 0;
  let amountToBeDeducted = 0;
  let price = 0;
  //SessionCurrency
  const currency = emp.state.defaultCurrency;
  console.log("EMP", emp);
  const updateUsers = async () => {
    let myData = await axios.get(
      `${API}/api/session/get-session/${emp.state.id}`,
      { headers: { token: userData.token } }
    );
    console.log("THIS SESSION USER IS REQUESTING FOR", myData.data);
    setUsers(myData.data);
  };

  useEffect(() => {
    if (!userData?.userId) {
      console.log("NOT LOGGED IN");
      navigate("/login");
    } else {
      updateUsers();
    }
    if (userData?.flag == "COUNSELLOR") {
      navigate("/Counsellor");
    }
  }, []);

  let bids = emp.state.bid.map((item) => {
    return { ...item, requestPay: false, paid: false };
  });

  if (users.length === 0) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  function onChange(event) {
    setAmount(event.target.value);
  }

  const checkBalance = async () => {
    updateUsers();

    console.log("REACHED HERE BEF UPD");
    const resd = await axios.get(`${API}/api/wallet/checkBalance`, {
      headers: {
        userId: userData.userId,
        sessionId: emp.state.id,
        entity: "Student",
        token: userData.token,
      },
    });
    console.log("REACHED HERE AFT UPD");
    sufficient = resd.data.sufficient;
    amountToBeAdded = resd.data.amountToBeAdded;
    return resd.data.sufficient;
  };

  const submitData = async (data) => {
    await new Promise((resolve, reject) => {
      console.log(userData);
      if (!userData.userId) {
        console.log("NOT LOGGED IN");
        navigate("/login");
        resolve();
      }
      resolve();
    });
    console.log("data", data);
    data.sessionid = users._id;
    data.counselorid = users.counsellorId._id;
    data.userId = userData.userId;
    data.sessionNumber = users.sessionNumber.length;
    data.bidAmount = users.sessionCost;
    data.sessionSlots = bids;
    data.leftBalance = users.sessionCost;
    console.log(users);
    let message = `${userData.fName} ${userData.lName} requested counselling on ${users.sessiontitle}`;
    let userid = {
      _id: users.counsellorId._id,
    };
    let notificationDetails = {
      msg: message,
      entity: "Session",
    };
    // debugger;
    console.log("REACHED HERE 1");
    try {
      setDisabled(true);
      //same sufficient currency
      console.log("REACHED HERE 1.5");
      let paymentDecision = await checkBalance();
      console.log("REACHED HERE 2");
      console.log("PAYMENT DECISION", paymentDecision);
      if (paymentDecision) {
        navigate("/SessionPay", {
          state: {
            datas: data,
            session: users,
            _id: userid._id,
            msg: message,
            defaultCurrency: emp.state.defaultCurrency,
            details: notificationDetails,
            userData: userid,
            data: emp,
            entity: "",
          },
        });
      } else {
        let userId = {
          _id: userData.userId,
        };
        let counselorid = {
          _id: users.counsellorId._id,
        };
        console.log("defaultToSessionCurrency", defaultToSessionCurrency);
        let id = { bidAmount: amountToBeAdded, currency: currency };
        let paymentDetails = await axios.get(
          `${API}/api/wallet/pay-counselling`,
          {
            params: {
              id: id,
            },
            headers: {
              userId: userData.userId,
              sessionId: emp.state.id,
              token: userData.token,
            },
          }
        );
        console.log(" Math.ceil(amountToBeAdded)", Math.ceil(amountToBeAdded));
        var options = {
          key: process.env.RAZORPAY_KEY_ID,
          order_id: paymentDetails.data.order.id,
          amount: Math.ceil(amountToBeAdded),
          // currency: `${userData.defaultCurrency}`,
          currency: `INR`,
          name: "Ooze IT Solutions Pvt Ltd",
          handler: async function (paymentDetails) {
            setPayment(true);
            try {
              const status = "SUCCESS";
              let details = {
                ...paymentDetails,
                ...id,
                status,
                userId,
                sessionId: emp.state.id,
                _id: users._id,
                counselorid,
                amount: amountToBeAdded,
                currency: emp.state.defaultCurrency,
                transactionType: "CREDIT",
                transactionVia: "Razorpay",
              };
              console.log("ADDED TO RAZORPAY", details);
              const addTransaction = await axios.post(
                `${API}/api/transaction/insert-transaction`,
                { data: { ...details } },
                {
                  headers: {
                    token: userData.token,
                  },
                }
              );
              console.log("TRANSACTION ADDED FOR RAZORPAY");
              const PaymentProcess = async () => {
                //amount add hoga as per currency
                debugger;
                const addMoneyToWallet = await axios.put(
                  `${API}/api/wallet/add-amounts/${userData.userId}`,
                  {
                    id: id,
                    userId: userData.userId,
                    sessionId: emp.state.id,
                    amount: amountToBeAdded,
                  },
                  {
                    headers: {
                      token: userData.token,
                    },
                  }
                );
                console.log("ADDED TO WALLET", addMoneyToWallet, userData);
                // console.log(addMoneyToWallet, "add",(addMoneyToWallet.data.balance >= users.sessionCost),addMoneyToWallet.data.balance , users.sessionCost);
                // let paymentRecheck = await checkBalance();
                // console.log("paymentRecheck", paymentRecheck);
                // if (paymentRecheck) {
                const res = await axios.post(
                  `${API}/api/wallet/paid-via-wallet`,
                  null,
                  {
                    headers: {
                      userId: userData.userId,
                      sessionId: emp.state.id,
                      bidCost: users.sessionCost,
                      sessionCurrency: emp.state.defaultCurrency,
                      entity: userData.flag,
                      token: userData.token,
                    },
                  }
                );
                console.log("ADDED TO ADMIN PAID VIA WALLET", res);
                const response = await axios.post(
                  `${API}/api/bidrequest/create`,
                  data,
                  { headers: { token: userData.token } }
                );
                console.log("BID REQUEST CREATED", response);
                const notificationResponse = await axios.post(
                  `${API}/api/notifications/update`,
                  { details: notificationDetails, userData: userid },
                  {
                    headers: { token: userData.token },
                  }
                );
                console.log("PAIDVIAWALLET");

                socket.emit("noti", { _id: userid._id, msg: message });
                socket.emit("sessionRequest", {
                  _id: userid._id,
                  msg: message,
                  data: emp,
                });
                console.log("PAIDVIAWALLET");
                // }
                // const removeFromProposal = await axios.put(
                //   `${API}/api/bidrequest/delete-bidrequest/${id._id}`
                // );
                socket.emit("noti", { _id: userData.userId });
                setPayment(false);
                alert("Payment Successful");
                navigate("/Students");
              };
              PaymentProcess();
            } catch (error) {
              console.log(error);
              setPayment(false);
              alert(error.message);
              navigate("/select");
            }
          },
        };

        const rzp1 = new Razorpay(options);
        rzp1.open();
        rzp1.on("payment.failed", async (response) => {
          const status = "FAILED";

          let details = { ...response, ...id, status };
          const addTransaction = await axios.post(
            `${API}/api/transaction/insert-transaction`,
            { data: details },
            {
              headers: { token: userData.token },
            }
          );
          alert(response.error.code);
        });
      }
    } catch (err) {
      console.log(err, "Accept");
    }
  };

  // const throttleSubmitData = throttle(submitData, 2000);

  return (
    <>
      {isPaymentDone && (
        <Modal show={isPaymentDone}>
          <Loader />
        </Modal>
      )}
      <SectionsHead heading="Submit a Bid" />
      <section className="bid mt-3 mb-5">
        <div className="contianer ">
          <div className="empty-counselling submit-bid">
            <div className="session-heading mb-3 ">
              <h6>Session Detail</h6>
            </div>
            <div className="row sess-clr">
              <p className="costmize-did-ditel col-lg-3 col-md-6">
                <span>Session Name :</span> <p>{users.sessiontitle}</p>
              </p>
              <p className="costmize-did-ditel col-lg-3 col-md-6">
                <span>Created date :</span>{" "}
                <p>{moment(new Date(users.createdAt)).fromNow()}</p>
              </p>
              <p className="costmize-did-ditel col-lg-3 col-md-6">
                <span>Session By :</span>{" "}
                <p>
                  {users?.counsellorId.fName.charAt(0).toUpperCase() +
                    users.counsellorId.fName.slice(1)}
                </p>
              </p>
              <p className="costmize-did-ditel mt-1 col-lg-3 col-md-6">
                {" "}
                <span>Session Cost :</span>{" "}
                <p>{` ${users.sessionCost} ${emp.state.defaultCurrency}`}</p>
              </p>
            </div>
            <p className="text-justify costmize-did-ditel col-lg-12 mt-3">
              <span className="">Desrciption : </span>
              <p>{users.sessionDes}</p>
            </p>
          </div>
        </div>
        <div className="contianer bid-section ">
          <form
            onSubmit={handleSubmit((data) => {
              if (wait) {
                return;
              }
              wait = true;
              setTimeout(() => {
                wait = false;
              }, 5000);
              submitData(data);
            })}
          >
            <p>Make a bid for your session?</p>
            <div>
              {users.sessionNumber.map((item, i) => {
                price = users.sessionCost;
                return (
                  <div className="bid-list" htmlFor="list1" key={i}>
                    <label className="bid-item" key={item.id}>
                      {item.sessions}
                    </label>
                    <label className="bid-item">{item.description} </label>
                  </div>
                );
              })}
              {errors?.sessionNumber?.type === "required" && (
                <p className="valid-txt m-0 fw-normal">Please Select Session</p>
              )}
            </div>
            <div></div>
            {/* <p>What is the price you'd like to bid for this session?</p>
            <span>Minimum session budget 500/-</span>
            <div className="price-input">
              <input
                type="number"
                {...register("bidAmount", {
                  required: "Bid Amount is required",
                  min: {
                    value: 500,
                    message: "Minimum amount is 500 Rupees",
                  },
                  max: {
                    value: users.sessionCost ? users.sessionCost : Infinity,
                    message: `Maximum amount is ${
                      users.sessionCost ? users.sessionCost : "Infinity"
                    } Rupees`,
                  },
                })}
                className="manual-price"
                onChange={onChange}
              />
              {errors?.bidAmount?.type === "required" && (
                <p className="valid-txt m-0 fw-normal">Insert Bid Amount</p>
              )}
              {errors?.bidAmount?.type === "min" && (
                <p className="valid-txt m-0 fw-normal">
                  Minimum Amount Upto 500 Rupees
                </p>
              )}
              {errors?.bidAmount?.type === "max" && (
                <p className="valid-txt m-0 fw-normal">
                  {errors.bidAmount.message}
                </p>
              )}
              <span className="unit">₹</span>
            </div> */}
            <div className="confirm-bid">
              <button type="submit" className="bid-submit">
                CONFIRM MY BID
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
// export { checkBalance };
export default Sessiondetails;

// const convertCurrency = async (requ, from, to) => {
//   debugger;
//   try {
//     const response = await freecurrencyapi.latest({
//       base_currency: from,
//       currencies: to,
//     });
//     const convertedAmount = response.data[to] * requ;
//     console.log(convertedAmount);
//     return convertedAmount.toFixed(2);
//   } catch (error) {
//     console.log(error);
//   }
// };
// const checkBalance = async () => {
//   updateUsers();
//   //userData.userId , emp.state.id
//   const resd = await axios.get(`${API}/api/wallet/checkBalance`, {
//     headers: {
//       userId: userData.userId,
//       sessionId: emp.state.id,
//     },
//   });
//   console.log("resd", resd);
//   sufficient = resd.data.sufficient;
//   amountToBeAdded = resd.data.amountToBeAdded;
//   return resd.data.sufficient;
// if (walletData[emp.state.defaultCurrency] >= users.sessionCost) {
//   console.log("1currencyCheck");
//   return true;
// } else {
//   debugger;
//   console.log("2currencyCheck");
//   let requiredSessionCurrency =
//     users.sessionCost - walletData[emp.state.defaultCurrency];
//   //requiredSessionCurrency USD amount from USDWallet short from session cost
//   if (walletData[userData.defaultCurrency] > 0) {
//     //inr wallet balance convert to USD to check sufficiency
//     const res = await convertCurrency(
//       walletData[userData.defaultCurrency],
//       userData.defaultCurrency,
//       emp.state.defaultCurrency
//     );
//     let result = res - 0.03 * res; //Available INR balance in USD
//     console.log(result);
//     //Check available INR converted to USD sufficient for requiredUSDLeft from USDWalletAmount
//     if (result >= requiredSessionCurrency) {
//       console.log("3currencyCheck");
//       //convert karo ki requiredSessionCurrency ke liye kitna katna hai user default currency me se
//       convertCurrency(
//         requiredSessionCurrency,
//         emp.state.defaultCurrency,
//         userData.defaultCurrency
//       ).then((res) => {
//         amountToBeDeducted = res;
//         defaultToSessionCurrency = res; //ye wo inr amount hai jo ki left USD dollar ke liye deduct hoga INR balance me se
//         let theUSD = requiredSessionCurrency; //ye wo USD amount hai jo ki deduct hoga USD wallet balance me se
//         return true;
//       });
//     } else {
//       //reqUserCur USD amount hai jisko inr me convert krke add karna hoga
//       let reqUserCur = requiredSessionCurrency - result;
//       console.log("4currencyCheck");
//       convertCurrency(
//         reqUserCur,
//         emp.state.defaultCurrency,
//         userData.defaultCurrency
//       ).then((res) => {
//         console.log("currencyCheck5", res);
//         defaultToSessionCurrency = res;
//         //ye wo inr amount hai jo ki left USD dollar ke liye add hoga INR wallet balance me and (walletData[userData.defaultCurrency] + defaultToSessionCurrency will be charged),  . walletData[emp.state.defaultCurrency] is USD to be charged
//         debugger;
//         return false;
//       });
//     }
//   } else {
//     debugger;
//     const result = await convertCurrency(
//       requiredSessionCurrency,
//       emp.state.defaultCurrency,
//       userData.defaultCurrency
//     );
//     console.log(result);
//     defaultToSessionCurrency = result; //defaultToSessionCurrency is the total amount to be added to INR and charged for session except from USD currency which is walletData[emp.state.defaultCurrency]
//     return false;
//   }
// }
// };
