import React, { useState, useRef, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import SectionsHead from "../../Home/Heading";
import { API } from "../../../api-config";
import axios from "axios";
import useOnClickOutside from "../../../hooks/use-onclick-outside";
import { ImUserPlus } from "react-icons/im";
import "../../../style/partials/Counsellor/tech-resignation.scss";
import Multiselect from "multiselect-react-dropdown";
import UserContext from "../../../contexts/UserContext";
import { Dropdown } from "react-bootstrap";
import { useForm } from "react-hook-form";
import $ from "jquery";

let file = "";
let wait = false;
let selectedCategory = [];
let selectedSubCategory = [];
let selectedSkills = [];
function CounsellorProfile() {
  // $(".btn-submit").click(() => {
  //   $(".validation").remove();

  //   let html = $(".searchWrapper span").text();
  //   if (html === "" || html == null) {
  //     $("#ID").after(
  //       "<p className='validation valid-prof'>Please select skill</p>"
  //     );
  //   }
  // });
  // $("#ID").click(() => {
  //   $(".validation").remove();
  // });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { userData, setUserData } = useContext(UserContext);
  let sub = [];

  const [state, setstate] = useState([]);
  const [country, setcountry] = useState([]);
  const [stu_address, setstu_address] = useState("");
  const [stu_desr, setstu_desr] = useState("");
  const [docfile, setdocfile] = useState("");
  const [skills, setskills] = useState([]);
  const [countryid, setcountryid] = useState("");
  const [statename, setstatename] = useState("");
  const [getskill, setgetskill] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categoriesAll, setCategoriesAll] = useState([]);
  const [skillsAll, setSkillsAll] = useState([]);
  const [subCategoriesAll, setSubCategoriesAll] = useState([]);
  const [selectedSkillSet, setSelectedSkillSet] = useState([]);
  const [skillr, setSkillR] = useState(10);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [listSkills, setListSkills] = useState([]);
  const [categ, setcateg] = useState([]);
  const [subCateg, setSubCateg] = useState([]);
  const [skillsLists, setSkillsLists] = useState([]);
  const [skillsAlert, setSkillsAlert] = useState(false);

  const [photo123, setphoto123] = useState(null);
  const [isDisabled, setDisabled] = useState(false);
  const [pro, setPro] = useState("");

  const ref = useRef();
  const userid = userData.userId;

  const [show, setShow] = useState(true);

  useEffect(() => {
    axios.get(`${API}/api/categories/allCat`).then((response) => {
      setCategoryList(response.data);
      setCategoriesAll(response.data);
    });
    axios.get(`${API}/api/subcateg/all-subCat`).then((response) => {
      setSubCategoryList(response.data);
      setSubCategoriesAll(response.data);
    });
  }, []);

  useOnClickOutside(ref, () => setShow(true));

  const handleInputChange = (event) => {
    setphoto123(URL.createObjectURL(event.target.files[0]));
    file = event.target.files[0];
    setPro(event.target.files[0]);
  };

  const submitData = (data) => {
    if (selectedSkills.length == 0) {
      setSkillsAlert((prevState) => true);
      console.log("TRUE");
    }
    const ims = document.querySelector('input[type="file"]');
    const formdatas = new FormData();
    formdatas.append("image", file);
    // formdatas.append("imaged", ims.files[0]);
    // formdatas.append("file", pro);
    const files = formdatas.get("image");
    console.log(countryid);
    // const html = $(".searchWrapper span").text();
    // if (html === "" || html === null) {
    if (
      selectedCategory.length < 1 ||
      selectedSubCategory.length < 1 ||
      selectedSkills.length < 1
    ) {
      $(".validation").show();
      console.log("ALL VALUES INCOMPLETED");
    } else {
      setDisabled(true);
      data.userId = userid;
      data.country = countryid;
      data.state = statename;
      data.address = stu_address;
      data.skills = selectedSkills;
      data.category = selectedCategory;
      data.subcategory = selectedSubCategory;
      data.description = stu_desr;
      data.docPath = docfile;
      data.profilePic = files;

      axios
        .post(`${API}/api/counselor-profile/create-counselor`, data)
        .then((response) => {
          try {
            axios
              .put(`${API}/api/frontregi/update/${userid}`, {
                isProfile: true,
              })
              .then((res) => {
                userData.isProfile = true;
                setUserData(userData);
                localStorage.setItem("userData", JSON.stringify(userData));
                setShow(false);
                axios
                  .get(`${API}/api/currency/getCurrency/${countryid}`)
                  .then((res) => {
                    console.log("RREESS ", res);
                    axios
                      .post(`${API}/api/frontregi/defaultCurrency`, {
                        userId: userData.userId,
                        currency: res.data[0].currency,
                      })
                      .then((resp) => {
                        console.log(res.data);
                        localStorage.setItem(
                          "userData",
                          JSON.stringify({
                            ...userData,
                            defaultCurrency: res.data[0].currency,
                          })
                        );
                        setUserData({
                          ...userData,
                          defaultCurrency: res.data[0].currency,
                        });
                        const upUD = localStorage.getItem("userData");
                        console.log(upUD, "CURRENCY", res.data[0].currency);
                        console.log(resp);
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  });
              });
          } catch (error) {
            console.log(error);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // useEffect(()=>{

  // },[selectedCategory,selectedSubCategory])
  const handleCategory = (id) => {
    if (selectedCategory.includes(id)) {
      selectedCategory.splice(selectedCategory.indexOf(id), 1);
      let newSub = subCateg.filter((item) => item.categories != id);
      setSubCateg(newSub);
      selectedSubCategory = selectedSubCategory.filter((ids) =>
        newSub.some((item) => item._id == ids)
      );
      if (selectedSubCategory.length > 0) {
        axios
          .get(`${API}/api/skills/getSkills`, {
            params: {
              subcat: newSub,
            },
          })
          .then((response) => {
            setSkillsLists(response.data);
            setSkillsAll(response.data);
            selectedSkills = selectedSkills.filter((ids) =>
              response.data.some((item) => item._id == ids)
            );
            console.log(
              "SELECTED CATEGORY",
              selectedCategory,
              "SELECTED SUBCATEGORY",
              selectedSubCategory,
              "SELECTED SKILLS",
              selectedSkills
            );
          });
      } else {
        setSkillsLists([]);
        selectedSkills = [];
      }
    } else {
      selectedCategory.push(id);
      const sub = subCategoryList.filter((item) => item.categories == id);
      setSubCateg((prevState) => [...prevState, ...sub]);
      console.log(
        "SELECTED CATEGORY",
        selectedCategory,
        "SELECTED SUBCATEGORY",
        selectedSubCategory,
        "SELECTED SKILLS",
        selectedSkills
      );
    }
  };
  const handleSubCategory = (id) => {
    if (selectedSubCategory.includes(id)) {
      selectedSubCategory = selectedSubCategory.filter((item) => item != id);
      axios
        .get(`${API}/api/skills/getSkills`, {
          params: { subcat: selectedSubCategory },
        })
        .then((response) => {
          setSkillsAll(response.data);
          setSkillsLists(response.data);
          selectedSkills = response.data
            .filter((item) => selectedSkills.includes(item._id))
            .map((item) => item._id);
        });
      console.log(
        "SELECTED CATEGORY",
        selectedCategory,
        "SELECTED SUBCATEGORY",
        selectedSubCategory,
        "SELECTED SKILLS",
        selectedSkills
      );
    } else {
      selectedSubCategory.push(id);
      let subCate = subCategoryList.find((item) => {
        if (item._id == id) {
          return item.categories;
        }
      });
      !selectedCategory.includes(subCate.categories) &&
        selectedCategory.push(subCate.categories);
      console.log(subCategoryList);
      console.log(
        "SELECTED CATEGORY",
        selectedCategory,
        "SELECTED SUBCATEGORY",
        selectedSubCategory,
        "SELECTED SKILLS",
        selectedSkills
      );
      axios
        .get(`${API}/api/skills/getSkills`, {
          params: { subcat: selectedSubCategory },
        })
        .then((response) => {
          console.log("RREESSPPOONNSEE", response);
          setSkillsAll((prevState) => response.data);
          setSkillsLists((prevState) => response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      // console.log(
      //   "SELECTED CATEGORY",
      //   selectedCategory,
      //   "SELECTED SUBCATEGORY",
      //   selectedSubCategory,
      //   "SELECTED SKILLS",
      //   selectedSkills
      // );
    }
  };
  const handleSkills = (item) => {
    if (selectedSkills.includes(item)) {
      selectedSkills = selectedSkills.filter((id) => item != id);
      setSkillR((prevState) => prevState - 1);
      console.log("CAMETODAADDD", selectedSkills);
    } else {
      selectedSkills.push(item);
      setSkillR((prevState) => prevState + 1);
      console.log("CAMETODAADDD", selectedSkills);
    }
  };
  useEffect(() => {
    const getcountry = async () => {
      let myData = await fetch(`${API}/api/country/get-Country`);
      let coutrydata = await myData.json();
      setcountry(await coutrydata);
    };
    getcountry();
  }, []);

  const handlestate = (event) => {
    const getcountryid = event.target.value;
    console.log(getcountryid, "master");
    setstatename(getcountryid);
  };

  const handlecountry = (event) => {
    const [_id, countryName] = event.target.value.split("_");
    getstate(_id);
    setcountryid(countryName);
  };

  const getstate = async (getcountryid) => {
    try {
      let myData = await axios.post(`${API}/api/state/get-statebycountry`, {
        countryId: getcountryid,
      });
      console.log(myData.data);
      setstate(myData.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelect = (selectedList) => {
    setskills(selectedList);
  };

  const handleRemove = (selectedList) => {
    setskills(selectedList);
  };

  return (
    <>
      {!userData.isProfile ? (
        <div ref={ref}>
          {` `}
          <div className="pl-0">
            {/* <Modal.Header className="m-auto">
              <SectionsHead heading="Counsellor Profile" />
            </Modal.Header> */}
            <div className="pop">
              <section className="studentprofile-pop">
                <div className="modal-content container " id="modalheader">
                  <form
                    className="tech-resignation"
                    onSubmit={handleSubmit((data) => {
                      if (wait) {
                        return;
                      }
                      wait = true;
                      setTimeout(() => {
                        wait = false;
                      }, 5000);
                      submitData(data);
                    })}
                    encType="multipart/form-data"
                  >
                    <div className="img-content row">
                      <div className="modal-header profile-box mt-1  col-lg-4">
                        <div
                          className="modal-title text-center profile m-auto"
                          id="exampleModalLabel"
                        >
                          <div className="custom-file-upload fas">
                            <div className="img-wrap img-upload">
                              {photo123 ? (
                                <img type="image" src={photo123} alt="upload" />
                              ) : (
                                <ImUserPlus className="up-icn" />
                              )}
                            </div>
                            <input
                              id="photo-upload"
                              type="file"
                              {...register("profilePic")}
                              accept="image/*"
                              onChange={handleInputChange}
                              name="image"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="compay-category col-lg-6 mt-4">
                        <div className="modal-body-div">
                          <label className="user-name">
                            {userData?.fName.charAt(0).trim().toUpperCase() +
                              userData?.fName.slice(1) +
                              " " +
                              userData?.lName.charAt(0).trim().toUpperCase() +
                              userData?.lName.slice(1)}
                          </label>
                          <input
                            placeholder="Company*"
                            onKeyPress={(e) => {
                              if (e.target.value === "" && e.key === " ") {
                                e.preventDefault();
                              }
                            }}
                            {...register("company", {
                              // required: true,
                              maxLength: 25,
                            })}
                          />
                          {errors?.company?.type === "required" && (
                            <p className="valid-prof">This field is required</p>
                          )}

                          {errors?.company?.type === "maxLength" && (
                            <p className="valid-prof">
                              Company name is too long
                            </p>
                          )}
                        </div>

                        <div id="ID " name="item">
                          <Dropdown>
                            <Dropdown.Toggle className="drop-btn-costmize ">
                              Select Category
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="drop-scroll-costmaize select-categry-sarch-bar">
                              <input
                                onChange={(e) => {
                                  console.log(e.target.value);
                                  let pattern = new RegExp(
                                    `${e.target.value}`,
                                    "i"
                                  );
                                  setCategoryList(() => {
                                    const aal = categoriesAll.filter((item) =>
                                      pattern.test(item.category)
                                    );
                                    console.log("CCAATTAALLL", aal);
                                    return aal.length > 0 ? aal : [];
                                  });
                                }}
                              ></input>
                              {categoryList &&
                                categoryList.map((item, index) => {
                                  return (
                                    <div className="drop-costmize " key={index}>
                                      <label key={`${item.category}_catlb`}>
                                        {subCategoryList && (
                                          <input
                                            type="checkbox"
                                            checked={selectedCategory.includes(
                                              item._id
                                            )}
                                            key={`${item.category}_catip`}
                                            onClick={() => {
                                              handleCategory(item._id);
                                            }}
                                          />
                                        )}
                                        <span>{item.category}</span>
                                      </label>
                                    </div>
                                    // <Dropdown.Item>{item.category}</Dropdown.Item>
                                  );
                                })}
                            </Dropdown.Menu>
                          </Dropdown>
                          <div className="category-item">
                            {selectedCategory.length > 0 &&
                              selectedCategory.map((item, index) => {
                                let temp = categoriesAll.find(
                                  (it) => it._id == item
                                );
                                if (temp) {
                                  return (
                                    <label key={index}>{temp.category}</label>
                                  );
                                } else {
                                  return "";
                                }
                              })}
                          </div>

                          <Dropdown>
                            <Dropdown.Toggle className="drop-btn-costmize">
                              Select SubCategory
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="drop-scroll-costmaize select-categry-sarch-bar">
                              <input
                                onChange={(e) => {
                                  console.log(e.target.value);
                                  let pattern = new RegExp(
                                    `${e.target.value}`,
                                    "i"
                                  );
                                  setSubCategoryList(() =>
                                    subCategoriesAll.filter((item) =>
                                      pattern.test(item.name)
                                    )
                                  );
                                }}
                              />
                              {subCategoryList &&
                                subCategoryList.map((item, index) => {
                                  return (
                                    <div className="drop-costmize" key={index}>
                                      <label key={`${item.name}_subcatlb`}>
                                        <input
                                          type="checkbox"
                                          checked={selectedSubCategory.includes(
                                            item._id
                                          )}
                                          key={`${item.name}_subcatip`}
                                          onClick={() => {
                                            handleSubCategory(item._id);
                                          }}
                                        />

                                        <span>{item.name}</span>
                                      </label>
                                    </div>
                                    // <Dropdown.Item>{item.category}</Dropdown.Item>
                                  );
                                })}
                            </Dropdown.Menu>
                          </Dropdown>
                          <div className="category-item">
                            {selectedSubCategory.length > 0 &&
                              selectedSubCategory.map((item, index) => {
                                let temp = subCategoriesAll.find(
                                  (it) => it._id == item
                                );
                                if (temp) {
                                  return <label key={index}>{temp.name}</label>;
                                } else {
                                  return "";
                                }
                              })}
                          </div>
                          <Dropdown>
                            <Dropdown.Toggle className="drop-btn-costmize">
                              Select Skills
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="drop-scroll-costmaize select-categry-sarch-bar">
                              <input
                                onChange={(e) => {
                                  let val = e.target.value.trim();
                                  if (val == "") {
                                    setSkillsLists(skillsAll);
                                    return;
                                  }
                                  let pattern = new RegExp(
                                    `${e.target.value}`,
                                    "i"
                                  );
                                  setSkillsLists(() =>
                                    skillsAll.filter((item) =>
                                      pattern.test(item.skill)
                                    )
                                  );
                                }}
                              ></input>
                              {skillr &&
                                skillsLists &&
                                skillsLists.map((item, index) => {
                                  return (
                                    <div className="drop-costmize" key={index}>
                                      <label key={`${item.skill}_catlb`}>
                                        <input
                                          checked={selectedSkills.includes(
                                            item._id
                                          )}
                                          type="checkbox"
                                          key={`${item.skill}_catip`}
                                          onClick={() => {
                                            handleSkills(item._id);
                                          }}
                                        />
                                        <span>{item.skill}</span>
                                      </label>
                                    </div>
                                    // <Dropdown.Item>{item.category}</Dropdown.Item>
                                  );
                                })}
                            </Dropdown.Menu>
                          </Dropdown>
                          {skillr && (
                            <div className="category-item">
                              {selectedSkills.map((item, index) => {
                                let temp = skillsAll.find(
                                  (it) => it._id == item
                                );
                                console.log("CAMETOUPDATE", temp);
                                if (temp) {
                                  return (
                                    <label key={index}>{temp.skill}</label>
                                  );
                                } else {
                                  return "";
                                }
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {skillsAlert && selectedSkills.length <= 0 && (
                      <h6>Please select some skills.</h6>
                    )}
                    {/* <Multiselect
                      id="ID"
                      name="item"
                      className="bord-div"
                      isObject={false}
                      selectedValues={skills}
                      onRemove={handleRemove}
                      onSelect={handleSelect}
                      placeholder="Select Skills"
                      options={getskill.map((item) => item.skills)}
                      showArrow
                    /> */}

                    <div className="modal-body-div row">
                      <div className="cheackneck col-lg-4 col-md-6">
                        <input
                          type="number"
                          step="0.01"
                          placeholder="E.g 3.5 , 3 in years and .5 in months "
                          onKeyDown={(e) => {
                            console.log(e.key, " ,", e.keyCode);
                            if (
                              e.keyCode == 38 ||
                              e.keyCode == 37 ||
                              e.keyCode == 39 ||
                              e.keyCode == 40
                            ) {
                              e.preventDefault();
                            }
                          }}
                          onWheel={(e) => {
                            e.preventDefault();
                          }}
                          {...register("experience", {
                            required: true,
                            validate: (value) => {
                              const numValue = parseFloat(value);
                              const integralPart = Math.floor(numValue);
                              const decimalPart = numValue - integralPart;
                              return integralPart <= 30 && decimalPart <= 0.11;
                            },
                            pattern: /^[0-9]+(\.[0-9]{1,2})?$/i,
                          })}
                          maxLength={5}
                        />

                        {errors?.experience?.type === "required" && (
                          <p className="valid-prof">This field is required</p>
                        )}
                        {errors?.experience?.type === "pattern" && (
                          <p className="valid-prof">Only Numeric Value</p>
                        )}
                        {errors?.experience?.type === "maxLength" && (
                          <p>Max length allowed is 5</p>
                        )}
                        {errors?.experience?.type === "validate" && (
                          <p className="valid-prof">
                            Max years of experience allowed is 30 and months is
                            .11
                          </p>
                        )}

                        <input
                          type="number" // Change type to "text" since you want to allow the plus sign
                          placeholder="Alternate Contact Number"
                          onKeyDown={(e) => {
                            console.log(e.key, " ,", e.keyCode);
                            if (
                              e.keyCode == 38 ||
                              e.keyCode == 37 ||
                              e.keyCode == 39 ||
                              e.keyCode == 40
                            ) {
                              e.preventDefault();
                            }
                          }}
                          onWheel={(e) => {
                            e.preventDefault();
                          }}
                          maxLength={13}
                          {...register("phone1", {
                            required: true,
                            minLength: 10,
                            maxLength: 13,
                            pattern: /^\+?[0-9]+$/i,
                          })}
                        />
                        {errors?.phone1?.type === "required" && (
                          <p className="valid-prof">This field is required</p>
                        )}
                        {errors?.phone1?.type === "pattern" && (
                          <p className="valid-prof">
                            Insert Valid Contact Number
                          </p>
                        )}
                        {errors?.phone1?.type === "minLength" && (
                          <p className="valid-prof">
                            Min 10 digit without Country Code
                          </p>
                        )}
                        {errors?.phone1?.type === "maxLength" && (
                          <p className="valid-prof">
                            Max 13 digit with Country Code
                          </p>
                        )}
                        <textarea
                          type="text"
                          {...register("addr", {
                            required: true,
                          })}
                          value={stu_address}
                          onChange={(e) => setstu_address(e.target.value)}
                          placeholder="Address"
                        />
                        {errors?.addr?.type === "required" && (
                          <p className="valid-prof">This field is required</p>
                        )}
                      </div>

                      <div className="checkbox-2 col-lg-4 col-md-6">
                        <input
                          placeholder="City"
                          onKeyPress={(e) => {
                            if (e.target.value.trim() === "" && e.key === " ") {
                              e.preventDefault();
                            }
                          }}
                          {...register("city", {
                            required: true,
                            maxLength: 20,
                            // pattern: /^[A-Za-z]+$/i,
                          })}
                        />
                        {errors?.city?.type === "required" && (
                          <p className="valid-prof">City field is required</p>
                        )}
                        {errors?.city?.type === "maxLength" && (
                          <p className="valid-prof">City name is too long</p>
                        )}
                        <select onChange={(e) => handlecountry(e)}>
                          <option defaultValue hidden>
                            {"Select Country"}
                          </option>
                          {country.map((getcon, index) => {
                            return (
                              <option
                                key={index}
                                value={
                                  getcon._id + "_" + getcon.countryName + "_"
                                }
                              >
                                {getcon.countryName}
                              </option>
                            );
                          })}
                        </select>
                        <select onChange={(e) => handlestate(e)}>
                          <option defaultValue hidden>
                            {"Select State"}
                          </option>
                          {state.map((getste, index) => {
                            return (
                              <option key={index} value={getste.stateName}>
                                {getste.stateName}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="checkbox-3 col-lg-4">
                        <input
                          type="number"
                          onKeyPress={(e) => {
                            if (e.target.value === "" && e.key === " ") {
                              e.preventDefault();
                            }
                          }}
                          onKeyDown={(e) => {
                            console.log(e.key, " ,", e.keyCode);
                            if (
                              e.keyCode == 38 ||
                              e.keyCode == 37 ||
                              e.keyCode == 39 ||
                              e.keyCode == 40
                            ) {
                              e.preventDefault();
                            }
                          }}
                          onWheel={(e) => {
                            e.preventDefault();
                          }}
                          placeholder="Zip Code"
                          maxLength={8}
                          {...register("zip", {
                            required: true,
                            maxLength: 8,
                          })}
                        />
                        {errors?.zip?.type === "required" && (
                          <p className="valid-prof">This field is required</p>
                        )}
                        {errors?.zip?.type === "maxLength" && (
                          <p>Max 8 digit allowed</p>
                        )}

                        <textarea
                          type="text"
                          {...register("desc", {
                            required: true,
                          })}
                          value={stu_desr}
                          onChange={(e) => setstu_desr(e.target.value)}
                          placeholder="Desrciption"
                        />
                        {errors?.desc?.type === "required" && (
                          <p className="valid-prof">This field is required</p>
                        )}
                        <div className="upl-btn mb-3">
                          <label>Upload Your Documents </label>
                          <input
                            type="file"
                            id="doument"
                            value={docfile}
                            name="accountDocument"
                            onChange={(e) => setdocfile(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="submit-btn text-center mb-2 mt-2">
                      <button
                        className=" btn btn-submit"
                        // disabled={isDisabled}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </section>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}
export default CounsellorProfile;
