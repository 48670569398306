import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../../style/partials/Counsellor/_counsellorprofile.scss";
import { API } from "../../../api-config";
import UserContext from "../../../contexts/UserContext";
import { useLocation } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { useForm } from "react-hook-form";
import teacher from "../../../images/bg2.png";
import SectionsHead from "../../Home/Heading";
let skillsid = [];
let allskills = [];
let skillsdropdown = [];
function UpdateProfile() {
  const { userData } = useContext(UserContext);
  const Navigate = useNavigate();
  const emp = useLocation();
  const [Fname, setFname] = useState("");
  const [Lname, setLname] = useState("");
  const [company, setcompany] = useState("");
  const [skills, setskills] = useState("");
  const [skillsname, setSkillsName] = useState([]);
  const [address, setaddress] = useState("");
  const [city, setcity] = useState("");
  const [selectstate, setselectstate] = useState("");
  const [selectcountry, setselectcountry] = useState("");
  const [zip, setzip] = useState("");
  const [number, setnumber] = useState(null);
  const [photo, setphoto] = useState("");
  const [country, setcountry] = useState([]);
  const [state, setstate] = useState([]);
  const [getskill, setgetskill] = useState([]);
  const [reqSkill, setReqSkill] = useState(false);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    try {
      const updateUsers = async () => {
        let myData = await axios.get(
          `${API}/api/counselor-profile/getCounselor`,
          {
            params: { id: userData.userId },
            headers: {
              token: userData.token,
            },
          }
        );
        console.log("SKILLS CHECK", myData);
        setValue("fname", myData.data[0].userId.fName);
        setValue("lname", myData.data[0].userId.lName);
        setValue("company", myData.data[0].company);
        setskills(myData.data[0].skills.map((item) => item.skill));
        setValue("address", myData.data[0].address);
        setValue("city", myData.data[0].city);
        setValue("state", myData.data[0].state);
        setselectcountry(myData.data[0].country);
        setValue("country", myData.data[0].country);
        setValue("zipcode", myData.data[0].zip);
        setValue("phone1", myData.data[0].phone1);
        setselectcountry(myData.data[0].country);
        setselectstate(myData.data[0].state);
        console.log(myData.data[0].state);
        setphoto(myData.data[0].portfoliopath);
        axios
          .post(`${API}/api/skills/getSkillsList`, {
            category: myData.data[0].category,
            subcategory: myData.data[0].subcategory,
          })
          .then((response) => {
            console.log("SKILLS LOADING", response);
            setgetskill(response.data);
            skillsdropdown = response.data;
            allskills = [];
            response.data.map((item) => {
              if (skillsid.includes(item._id)) {
                console.log(item.skill);
                allskills.push({ skill: item.skill, id: item._id });
              }
            });
            setSkillsName(allskills);
          });
        skillsid = myData.data[0].skills.map((item) => item._id);
      };

      updateUsers();
    } catch (error) {
      console.log("UPDATE PROFILE DATA LOADING ERROR", error);
    }
  }, []);

  const submit = (e) => {
    if (skillsid.length <= 0) {
      return;
    }
    const post = {
      fName: e.fname,
      lName: e.lname,
      company: e.company,
      skills: skillsid,
      address: e.address,
      city: e.city,
      state: e.state,
      country: e.country,
      zip: e.zipcode,
      number: e.phone1,
    };

    axios
      .put(`${API}/api/counselor-profile/counselor-update/${emp.state}`, post)
      .then((res) => {
        Navigate("/Counsellor-profile");
      });
  };
  const getcountry = async () => {
    let myData = await axios.get(`${API}/api/country/get-Country`);

    setcountry(myData.data);
  };
  useEffect(() => {
    getcountry();
  }, []);

  const handlecountry = (value) => {
    let countryid = country.find((key) => key.countryName === value);
    setselectstate(() => "");
    let selectedCountryId = countryid ? countryid._id : null;
    if (selectedCountryId) {
      getstate(selectedCountryId);
    }
  };

  const getstate = async (getcountryid) => {
    let myData = await axios.post(`${API}/api/state/get-statebycountry`, {
      countryId: getcountryid,
    });

    setstate(myData.data);
  };
  // debugger;
  const handlestate = (event) => {
    const getcountryid = event.target.value;
    setselectstate(getcountryid);
  };
  const updateSelectedSkill = () => {
    let names = [];
    getskill.map((item) => {
      if (skillsid.includes(item._id)) {
        names.push({ skill: item.skill, id: item._id });
      }
    });
    setSkillsName(() => {
      console.log(names);
      return names;
    });
  };
  const handleChangeId = () => {
    let names = [];
    const skill = document.getElementById("skill");
    // if (skillsid.some((id) => id == skill.value)) {
    //   skillsid = skillsid.filter((ids) => ids != skill.value);
    //   updateSelectedSkill();
    // getskill.map((item) => {
    //   if (skillsid.includes(item._id)) {
    //     console.log(item._id);
    //     console.log(item.skill);
    //     names.push({ skill: item.skill, id: item._id });
    //     console.log(names);
    //   }
    // });
    // console.log(names);
    // setSkillsName(names);
    // } else {
    console.log(skillsid);
    skillsid.push(skill.value);
    updateSelectedSkill();
    // getskill.map((item) => {
    //   if (skillsid.includes(item._id)) {
    //     console.log(item.skill);
    //     names.push({ skill: item.skill, id: item._id });
    //     console.log(names);
    //   }
    // });
    // setSkillsName(names);
    // console.log(skillsid);
    // }
    // console.log(skillsid);
    // console.log(skillsname);
  };
  const handleSelect = (selectedList) => {
    console.log("SELECTED SKILL", selectedList);
    setskills(selectedList);
  };
  const handleRemove = (selectedList) => {
    setskills(selectedList);
  };
  return (
    <>
      <section className="p-3 update-profile">
        <SectionsHead heading="Update Profile" />
        <div className="profile-main-title text-center mt-1"></div>
        <form
          className=" counselling-profile profile-form  container shadow"
          onSubmit={handleSubmit(submit)}
        >
          <div className="row text-center">
            <div className=" col-md-6 ">
              <div className="shadow-sm update-image-box">
                <img src={teacher} className="profile-img" alt="profile-img" />
              </div>
              <input
                className="input-field "
                onKeyPress={(e) => {
                  if (e.target.value === "" && e.key === " ") {
                    e.preventDefault();
                  }
                }}
                {...register("fname", {
                  required: true,
                  validate: (value) => value.trim() !== "",
                  maxLength: 20,
                  pattern: /^[a-zA-Z\s]*[a-zA-Z][a-zA-Z\s]*$/,
                })}
              />
              {errors?.fname?.type === "required" && (
                <p>Please Enter FirstName</p>
              )}
              {errors?.fname?.type === "pattern" && (
                <p>Alphabetical characters only</p>
              )}
              <input
                className="input-field"
                type="text"
                onKeyPress={(e) => {
                  if (e.target.value === "" && e.key === " ") {
                    e.preventDefault();
                  }
                }}
                {...register("lname", {
                  required: true,
                  validate: (value) => value.trim() !== "",
                  maxLength: 20,
                  pattern: /^[a-zA-Z\s]*[a-zA-Z][a-zA-Z][a-zA-Z\s]*$/,
                })}
              />
              {errors?.lname?.type === "required" && (
                <p>Please Enter LastName</p>
              )}
              {errors?.lname?.type === "pattern" && (
                <p>Alphabetical characters only</p>
              )}
              <input
                className="input-field"
                type="number"
                placeholder="Alternate Contact Number"
                onKeyDown={(e) => {
                  console.log(e.key, " ,", e.keyCode);
                  if (
                    e.keyCode == 38 ||
                    e.keyCode == 37 ||
                    e.keyCode == 39 ||
                    e.keyCode == 40
                  ) {
                    e.preventDefault();
                  }
                }}
                onWheel={(e) => {
                  e.preventDefault();
                }}
                {...register("phone1", {
                  required: true,
                  validate: (value) => {
                    if (
                      value !== "" &&
                      value.length <= 13 &&
                      value.length >= 10
                    ) {
                      return true;
                    }
                  },
                  maxLength: 13,
                })}
                name="phone1"
              />
              {errors?.phone1?.type === "required" && (
                <p>Enter the mobile number</p>
              )}
              {errors?.phone1?.type === "maxLength" && (
                <p>Max 13 Length Allowed</p>
              )}
              {errors?.phone1?.type === "validate" && (
                <p>Min 10 digit allowed max 13 digit with country code</p>
              )}
              <input
                className="input-field"
                type="text"
                onKeyPress={(e) => {
                  if (e.target.value === "" && e.key === " ") {
                    e.preventDefault();
                  }
                }}
                {...register("company", {
                  required: true,
                  validate: (value) => value.trim() !== "",
                  maxLength: 20,
                })}
                placeholder="Company"
              />

              <select id="skill" onChange={() => handleChangeId()}>
                <option disabled>Select Skills</option>
                {getskill.map((item, index) => (
                  <option value={item._id}>{item.skill}</option>
                ))}
              </select>
              {skillsname && (
                <div className="bord-div">
                  {skillsname.map((item) => {
                    return (
                      <div className="skill-add-div">
                        <p>{item.skill}</p>
                        <button
                          type="button"
                          onClick={() => {
                            console.log(
                              "ENTERED SKILLS REMOVING",
                              item.id,
                              item
                            );
                            skillsid = skillsid.filter((ids) => ids != item.id);
                            skillsdropdown = skillsdropdown.filter(
                              (ids) => ids != item.id
                            );
                            console.log(skillsid);
                            updateSelectedSkill();
                            skillsid.length <= 0
                              ? setReqSkill((prevState) => true)
                              : setReqSkill((prevState) => false);
                            console.log(skillsdropdown, reqSkill);
                          }}
                        >
                          X
                        </button>
                      </div>
                    );
                  })}
                </div>
              )}

              {reqSkill && skillsid.length <= 0 && (
                <p style={{ color: "red" }}>Minimum 1 skill is mandatory</p>
              )}
            </div>
            {/* <div className=" col-md-6"> */}

            {/* <input
                className="input-field "
                onKeyPress={(e) => {
                  if (e.target.value === "" && e.key === " ") {
                    e.preventDefault();
                  }
                }}
                {...register("fname", {
                  required: true,
                  validate: (value) => value.trim() !== "",
                  maxLength: 20,
                  pattern: /^[a-zA-Z\s]*[a-zA-Z][a-zA-Z\s]*$/,
                })}
              />
              {errors?.fname?.type === "required" && (
                <p>Please Enter FirstName</p>
              )}
              {errors?.fname?.type === "pattern" && (
                <p>Alphabetical characters only</p>
              )} */}

            {/* </div> */}
            <div className=" col-md-6">
              <textarea
                className="input-field"
                type="text"
                onKeyPress={(e) => {
                  if (e.target.value === "" && e.key === " ") {
                    e.preventDefault();
                  }
                }}
                {...register("address", {
                  required: true,
                  validate: (value) => value.trim() !== "",
                })}
                placeholder="Address"
              />
              {errors?.address && <p>Please enter address</p>}
              <select
                {...register("country", {
                  required: true,
                  validate: (value) => value.trim() !== "",
                })}
                onChange={(e) => {
                  handlecountry(e.target.value);
                }}
              >
                <option defaultValue hidden>
                  {selectcountry}
                </option>
                {country.map((getcon) => {
                  return <option key={getcon._id}>{getcon.countryName}</option>;
                })}
              </select>

              {errors?.country?.type == "required" && <p>Select Country</p>}
              <select
                {...register("state", {
                  required: true,
                  validate: (value) => value.trim() !== "",
                })}
              >
                {selectstate && (
                  <option defaultValue hidden>
                    {selectstate}
                  </option>
                )}
                {state.map((getste, index) => {
                  return (
                    <option key={index} value={getste.stateName}>
                      {getste.stateName}
                    </option>
                  );
                })}
              </select>
              {errors?.state && <p>Select State</p>}
              <input
                className="input-field"
                onKeyPress={(e) => {
                  if (e.target.value === "" && e.key === " ") {
                    e.preventDefault();
                  }
                }}
                {...register("city", {
                  required: true,
                  validate: (value) => value.trim() !== "",
                })}
                placeholder="City"
                name="city"
              />
              {errors?.city && <p>Enter City Name</p>}
              <input
                className="input-field"
                type="number"
                placeholder="Zip Code"
                onKeyDown={(e) => {
                  console.log(e.key, " ,", e.keyCode);
                  if (
                    e.keyCode == 38 ||
                    e.keyCode == 37 ||
                    e.keyCode == 39 ||
                    e.keyCode == 40
                  ) {
                    e.preventDefault();
                  }
                }}
                onWheel={(e) => {
                  e.preventDefault();
                }}
                {...register("zipcode", {
                  required: true,
                  maxLength: 8,
                  validate: (value) => {
                    if (value !== "" && value.length <= 8) {
                      return true;
                    }
                  },
                })}
              />
              {errors?.zipcode?.type === "required" && <p>Enter the zipcode</p>}
              {errors?.zipcode?.type === "validate" && (
                <p>Max 8 digit allowed</p>
              )}
              {errors?.zipcode?.type === "maxLength" && (
                <p>Max 8 digit allowed</p>
              )}
            </div>
            <div className="mt-3 mb-3">
              <button type="submit" className="update-profile">
                Update Profile
              </button>
            </div>
            {/* <div className=" col-md-6">

              </div>
              
              <div className=" col-md-6">
              {errors?.company && <p>Enter Company Name</p>}


              </div>

              <div className=" col-md-6">



              </div> */}
            {/* <div className=" col-md-6  col-12  image-box-prfile main-profile-updt-div ">
              <div className="blur-bg-profile"></div>
              <div className="img-contanent-btn">
                <img src={teacher} className="profile-img" alt="profile-img" />
              </div>

              <button type="submit" className="profile-sub-btn">
                Update Profile
              </button>
            </div> */}

            {/* <div className="profile-input col-md-6 col-12 text-center "> */}

            {/* <Multiselect
                onKeyPress={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
                id="ID"
                name="item"
                className="bord-div"
                isObject={false}
                selectedValues={skills}
                onRemove={handleRemove}
                onSelect={handleSelect}
                placeholder="Select Skills"
                options={getskill.map((item, index) => item.skill)}
                showArrow
              /> */}
            {/* {errors?.skills && <p>Enter some skills</p>} */}

            {/* </div> */}
          </div>
        </form>
      </section>
    </>
  );
}

export default React.memo(UpdateProfile);
