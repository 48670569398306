import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { API } from "../../../api-config";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import "../../../style/partials/Counsellor/_conformation.scss";
import Loader from "../../Loader/Loader";
import UserContext from "../../../contexts/UserContext";
import { useLocation } from "react-router-dom";
import useRazorpay from "react-razorpay";
import socket from "../../../socket/socketio";
import { Modal } from "react-bootstrap";
import { head } from "lodash";
import SectionsHead from "../../Home/Heading";

let waitAccept = false;
let waitReject = false;
let sufficient = "";
let amountToBeAdded = 0;
function Conformation() {
  const navigate = useNavigate();
  const [Razorpay] = useRazorpay();
  const [biddata, Setbiddata] = useState([]);
  const [rejecteRequested, setRejectRequested] = useState(false);
  const [user, Setuser] = useState([]);
  const [scheduled, setScheduled] = useState([]);
  const [skill, Setskill] = useState([]);
  const { userData } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [isPaymentDone, setPayment] = useState(false);

  const {
    register,
    formState: { errors },
  } = useForm();
  const emp = useLocation();
  const updateUsers = async () => {
    await axios
      .post(`${API}/api/bidrequest/counsellor-bid-request`, {
        sessionid: emp.state,
      })
      .then((res) => {
        console.log("BID DATA ", res.data);
        Setbiddata(res.data);
      });
  };
  console.log("EMP", emp);
  useEffect(() => {
    const getNonScheduled = async () => {
      const scheduleData = await axios.get(
        `${API}/api/bidrequest/nonscheduled`,
        { headers: { userId: userData.userId, session: emp.state } }
      );
      setScheduled(scheduleData.data);
      console.log(scheduleData.data);
    };
    getNonScheduled();
    socket.on(`requestProp${userData.userId}`, () => {
      try {
        updateUsers();
      } catch (error) {
        console.log("ERRORS IN SOCKET", error);
      }
    });
    updateUsers();
    return () => {
      socket.off(`requestProp${userData.userId}`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);
  const consid = userData.userId;
  // console.log("test", biddata.id)
  useEffect(() => {
    try {
      const updateData = async () => {
        await axios
          .get(`${API}/api/session/get-session/${emp.state}`, {
            headers: { token: userData.token },
          })
          .then((res) => {
            console.log(res.data);
            Setuser(res.data);
          });
        //
        // let myData = await axios.post(
        //   `${API}/api/counselor-profile/get-counselor-skill`,
        //   {
        //     userId: userData.userId,
        //   }
        // );
        // // console.log("SSKKIILLSS   ", myData.data.skills);
        // Setskill(myData.data.skills);
      };
      updateData();
    } catch (error) {
      console.log("error in getting session", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (user.length === 0 || isPaymentDone) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  const checkBalance = async () => {
    const resd = await axios.get(`${API}/api/wallet/checkBalance`, {
      headers: {
        sessionId: user._id,
        entity: "counsellor",
        userId: userData.userId,
        token: userData.token,
      },
    });
    console.log(resd.data);
    sufficient = resd.data.sufficient;
    amountToBeAdded = resd.data.amountToBeAdded;
    return resd.data.sufficient;
  };
  const scheduleSession = (id, bidAmount, userid, item) => {
    let info = {
      bidId: id,
      counsellorId: item.counselorid._id,
      userId: userid._id,
      sessionId: user._id,
      sessiontitle: user.sessiontitle,
      skills: user.skills,
      sessionDes: user.sessionDes,
      noOfSessions: item.sessionNumber,
      sessionDetails: item.sessionSlots,
    };
    navigate(`/Eventcreate`, {
      state: {
        state: info,
        sessions: user.sessionNumber,
        leftBal: item.bidAmount,
      },
    });
  };
  const Accept = async (id, bidAmount, userid, item) => {
    try {
      let message = `${item.counselorid.fName} ${item.counselorid.lName} Accepted your counselling request on ${item.sessionid.sessiontitle}`;

      let notificationDetails = {
        msg: message,
        entity: "Session",
      };
      let ids = { bidAmount: amountToBeAdded, bidId: id._id };
      // console.log("FID BID ID", id, bidAmount, userid, item);
      // START FROM HERE PEHLE CHECK BALANCE JAYEGA FIR PAYMENT HOGA FIR ACCEPT HOGA
      let paymentDecision = await checkBalance();
      // console.log(paymentDecision);
      if (paymentDecision) {
        navigate("/SessionPay", {
          state: {
            session: user,
            _id: userid._id,
            msg: message,
            defaultCurrency: user.defaultCurrency,
            details: notificationDetails,
            userData: userid,
            data: emp,
            entity: "counsellor",
            bidId: id,
            // counsellorId: response.data.counselorId,
            sessiontitle: user.sessiontitle,
          },
        });
      } else {
        console.log("paymentDecision", paymentDecision);
        let paymentDetails = await axios.get(
          `${API}/api/wallet/pay-counselling`,
          {
            params: {
              id: ids,
            },
            headers: {
              userId: userData.userId,
              entity: "counsellor",
              sessionId: emp.state,
              token: userData.token,
            },
          }
        );
        console.log(" Math.ceil(amountToBeAdded)", Math.ceil(amountToBeAdded));
        var options = {
          key: process.env.RAZORPAY_KEY_ID,
          order_id: paymentDetails.data.order.id,
          amount: Math.ceil(amountToBeAdded),
          // currency: `${userData.defaultCurrency}`,
          currency: `INR`,
          name: "Ooze IT Solutions Pvt Ltd",
          handler: async function (paymentDetails) {
            try {
              debugger;
              setPayment((prevState) => !prevState);
              console.log(paymentDetails);
              const status = "SUCCESS";
              let details = {
                ...paymentDetails,
                ...ids,
                status,
                consid,
                _id: user._id,
              };

              const res = await axios.put(
                `${API}/api/bidrequest/confirm-bidrequest/${id}`,
                {
                  headers: {
                    token: userData.token,
                  },
                },
                {
                  headers: {
                    token: userData.token,
                  },
                }
              );
              console.log(res, user.sessionNumber, item);
              let info = {
                bidId: res.data.id,
                counsellorId: item.counselorid._id,
                userId: userid._id,
                sessionId: user._id,
                sessiontitle: user.sessiontitle,
                skills: user.skills,
                sessionDes: user.sessionDes,
                noOfSessions: item.sessionNumber,
                sessionDetails: item.sessionSlots,
              };
              console.log("CONFIRMED BID REQUEST", amountToBeAdded);
              const addTransaction = await axios.post(
                `${API}/api/transaction/insert-transaction`,
                {
                  data: {
                    ...details,
                    bidReqId: id,
                    userId: { _id: item.counselorid._id },
                    amount: amountToBeAdded,
                    bidAmount: amountToBeAdded,
                    sessionId: user._id,
                    counselorid: { _id: item.counselorid._id },
                    currency: user.defaultCurrency,
                    transactionType: "CREDIT",
                    transactionVia: "Razorpay",
                  },
                },
                { headers: { token: userData.token } }
              );
              //amount add hoga as per currency
              console.log("REACHED HERE", addTransaction);
              const addMoneyToWallet = await axios.put(
                `${API}/api/wallet/add-amounts/${userData.userId}`,
                {
                  bidId: id._id,
                  amount: amountToBeAdded,
                  userId: userData.userId,
                  sessionId: emp.state,
                },
                {
                  headers: {
                    token: userData.token,
                  },
                }
              );

              const resWal = await axios.post(
                `${API}/api/wallet/paid-via-wallet`,
                null,
                {
                  headers: {
                    userId: userData.userId,
                    sessionId: emp.state,
                    bidCost: Number(0.03 * user.sessionCost),
                    sessionCurrency: user.defaultCurrency,
                    entity: "counsellor",
                    token: userData.token,
                  },
                }
              );

              const notificationResponse = await axios.post(
                `${API}/api/notifications/update`,
                {
                  details: notificationDetails,
                  userData: userid,
                },
                { headers: { token: userData.token } }
              );
              console.log("userid IS", userid);
              console.log(notificationResponse);
              socket.emit("noti", {
                userId: userid._id,
                bidId: id,
                item: item,
                _id: userid._id,
                msg: message,
              });
              socket.emit("sessionDecision", {
                userId: userid._id,
                bidId: id,
                item: item,
                _id: userid._id,
                msg: message,
                status: "ACCEPTED",
              });
              navigate(`/Eventcreate`, {
                state: {
                  state: info,
                  sessions: user.sessionNumber,
                  leftBal: item.bidAmount,
                },
              });
              // console.log(
              //   "RES.DATA.ID",
              //   res.data.id,
              //   "counsellorId",
              //   item.counselorid._id,
              //   "userId",
              //   userid._id,
              //   "sessionId",
              //   user._id,
              //   "sessiontitle",
              //   user.sessiontitle,
              //   "user.skills",
              //   user.skills,
              //   "user.sessionDes",
              //   user.sessionDes,
              //   "item.sessionNumber",
              //   item.sessionNumber,
              //   "item.sessionSlots",
              //   item.sessionSlots,
              //   "...details",
              //   ...details,
              //   "bidReqId: id",
              //   id,
              //   " userId: { _id: userid._id }",
              //   userid._id,
              //   "amountToBeAdded",
              //   amountToBeAdded,
              //   "user.defaultCurrency",
              //   user.defaultCurrency,
              //   "bidId: id._id",
              //   id._id,
              //   "sessionId: emp.state",
              //   emp.state,
              //   "userId: userData.userId",
              //   userData.userId,
              //   "bidCost: Number(0.03 * user.sessionCost)",
              //   Number(0.03 * user.sessionCost),
              //   "sessionCurrency: user.defaultCurrency",
              //   user.defaultCurrency,
              //   " entity",
              //   "counsellor"
              // );
              setPayment(false);
              alert("Payment Successful");
              // navigate("/Students");
            } catch (error) {
              console.log(error);
            }
          },
        };
        const rzp1 = new Razorpay(options);
        rzp1.open();
        rzp1.on("payment.failed", async (response) => {
          const status = "FAILED";
          let details = { ...response, ...id, status };
          const addTransaction = await axios.post(
            `${API}/api/transaction/insert-transaction`,
            { data: details }
          );
          alert(response.error.code);
        });
        console.log(paymentDetails);
      }
    } catch (err) {
      console.log(err, "Accept");
    }
  };

  const Reject = async (id, bidAmount, userid, item) => {
    //console.log(id,"id")
    console.log("SBKUCH", id, userid, item);
    let message = `${userData.fName} ${userData.lName} Rejected your counselling request on ${item.sessionid.sessiontitle}`;
    let notificationDetails = {
      msg: message,
      entity: "Session",
    };

    try {
      axios.put(`${API}/api/bidrequest/reject-bidrequest/${id}`).then((res) => {
        console.log(res, "deletd");
        Setbiddata((prevState) => prevState.filter((item) => item._id != id));
      });
      const notificationResponse = await axios.post(
        `${API}/api/notifications/update`,
        { details: notificationDetails, userData: userid }
      );
      socket.emit("noti", {
        userId: userid,
        bidId: id,
        item: item,
        _id: userid._id,
        msg: message,
      });
      socket.emit("sessionDecision", {
        userId: userid,
        bidId: id,
        item: item,
        _id: userid._id,
        msg: message,
        status: "REJECTED",
      });
    } catch (err) {
      console.log(err, "Raject");
    }
  };

  const EditSession = () => {
    // debugger;
    // console.log(userData);
    setShow(true);
    console.log("IITTEEMM   ", biddata);
  };

  return (
    <section className="request-confirm-div container">
      <SectionsHead heading="Session INFO" />
      <div className="container session-detailing mb-5">
        <div className="session-box p-3">
          {/* <div className="session-heading mb-3"></div> */}
          <div className="row">
            <div className="col-12">
              <div className="row sess-clr">
                <div className="col-md-6 col-12 ">
                  <div className="shadow-sm session-dtl-list-1">
                    <p className="fw-bold">
                      Session Name : <strong>{user.sessiontitle}</strong>
                    </p>
                  </div>
                </div>

                <div className="col-md-6 col-12 ">
                  <div className="shadow-sm session-dtl-list">
                    <p className="fw-bold">
                      Created date :{" "}
                      <strong>
                        {user.createdAt.toString().substring(0, 10)}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                {user.sessionNumber.length > 0 &&
                  user.sessionNumber?.map((item, index) => {
                    console.log("TIHSISITEM", item);
                    return (
                      <div className="col-md-4">
                        <div className="shadow-sm session-dtl-list mt-3 mb-3">
                          <div className=" sess-clr" key={index}>
                            <p key={item?.id} className="fw-bold">
                              Session No. : <strong>{item?.sessions}</strong>
                            </p>
                          </div>
                          <div className="sess-clr">
                            <p className="fw-bold">
                              Price :{" "}
                              <strong>{`${item?.Price} ${user.defaultCurrency}`}</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="row">
                <div className="col-md-12 ">
                  <div className=" shadow-sm session-dtl-list">
                    <p className="text-justify mt-1">
                      <span className="fw-bold">Desrciption : </span>
                      {user?.sessionDes?.charAt(0).toUpperCase() +
                        user?.sessionDes?.slice(1)}
                    </p>
                    <button
                      className="col-2 edit-btn"
                      onClick={() => EditSession()}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {biddata.length > 0 &&
          biddata?.map((item, index) => {
            console.log("AAIIITTTEEEMMM", item);
            console.log(scheduled, scheduled.includes(item._id));
            return (
              <div className="session-more-detail row" key={index}>
                <div className="col-lg-12">
                  <div className="d-flex mt-1 mb-1">
                    <div className="col-6 shadow-sm session-dtl-list-1">
                      <h6>Bid By :</h6>
                    </div>
                    <div className="col-6 shadow-sm session-dtl-list">
                      <p>
                        {item.userId.fName.charAt(0).toUpperCase() +
                          item.userId.fName.slice(1)}{" "}
                        {/* {item.counselorid.lName.charAt(0).toUpperCase() +
                        item.counselorid.lName.slice(1)}{" "} */}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mt-1 mb-1">
                    <div className="col-6 shadow-sm session-dtl-list-1">
                      <h6>For Session:</h6>
                    </div>
                    <div className="col-6 shadow-sm session-dtl-list">
                      <p>{item.sessionNumber}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="d-flex mt-1 mb-1">
                    <div className="col-6 shadow-sm session-dtl-list-1">
                      <h6>Bid Price:</h6>
                    </div>
                    <div className="col-6 shadow-sm session-dtl-list">
                      <p>{`${item.bidAmount} ${user.defaultCurrency}`}</p>
                    </div>
                  </div>
                  <div className="bid-btn col-lg-6">
                    {scheduled.includes(item._id) && (
                      <button
                        className="btn-primary schedule-btn"
                        onClick={() => {
                          scheduleSession(
                            item._id,
                            item.bidAmount,
                            item.userId,
                            item
                          );
                        }}
                      >
                        Schedule
                      </button>
                    )}
                    {item.conformation === true ? (
                      <div>
                        {" "}
                        <button
                          type="button"
                          id=""
                          className="accept-btn"
                          //onClick={() => Accept(item._id,item.bidAmount,item.userId)}
                        >
                          ACCEPTED
                        </button>
                      </div>
                    ) : (
                      <>
                        <div>
                          {" "}
                          <button
                            type="button"
                            id=""
                            className="accept-btn"
                            onClick={() => {
                              if (waitAccept) {
                                return;
                              }
                              waitAccept = true;
                              setTimeout(() => {
                                waitAccept = false;
                              }, 4000);
                              Accept(
                                item._id,
                                item.bidAmount,
                                item.userId,
                                item
                              );
                            }}
                          >
                            ACCEPT
                          </button>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="reject-btn"
                            onClick={() => {
                              if (waitReject) {
                                return;
                              }
                              setRejectRequested((prevState) => true);
                              waitReject = true;
                              setTimeout(() => {
                                waitReject = false;
                                setRejectRequested((prevState) => false);
                              }, 4000);
                              Reject(
                                item._id,
                                item.bidAmount,
                                item.userId,
                                item
                              );
                            }}
                          >
                            REJECT
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <Modal show={show} className="pl-0">
        <Modal.Header
          closeButton
          onClick={() => {
            setShow(false);
          }}
        />
        <Modal.Body className="pop">
          <section className="studentprofile-pop ">
            <div className="modal-content  container" id="modalheader">
              <form
                // onSubmit={handleSubmit((data) => {
                //   if (wait) {
                //     return;
                //   }
                //   wait = true;
                //   setTimeout(() => {
                //     wait = false;
                //   }, 5000);
                //   submitData(data);
                // })}
                encType="multipart/form-data"
                className="session-dtl-pop-fnt"
              >
                <div className="modal-header mt-1">
                  <h2>Session Detail</h2>
                </div>

                <div className="modal-body-div">
                  {/* <div>
                    <p>Skills</p>
                  </div> */}

                  {/* <select>
                    <option defaultValue hidden>
                      {user.skills}
                    </option>
                    {skill.map((sk, index) => {
                      return (
                        <option key={index} value={sk}>
                          {sk}
                        </option>
                      );
                    })}
                  </select> */}
                  {user.sessionNumber.length > 0 &&
                    user.sessionNumber?.map((item, index) => {
                      // debugger;
                      return (
                        <div className="modal-body-div">
                          <div key={index}>
                            <div>
                              <p>No of sessions</p>
                            </div>
                            <input
                              key={index}
                              placeholder={item?.sessions}
                              defaultValue={item?.sessions}
                              onKeyPress={(e) => {
                                if (e.target.value === "" && e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                              {...register(`noOfSessions${index}`, {
                                required: true,
                                maxLength: 2,
                                pattern: "",
                              })}
                            />
                            {errors?.noOfSessions?.type === "required" && (
                              <p className="valid-prof">
                                This field is required
                              </p>
                            )}

                            {errors?.noOfSessions?.type === "maxLength" && (
                              <p className="valid-prof">Limit your sessions</p>
                            )}
                          </div>
                          <div className="modal-body-div">
                            <div>
                              <p>Price</p>
                            </div>

                            <input
                              // key={index}
                              placeholder={item?.Price}
                              defaultValue={item?.Price}
                              onKeyPress={(e) => {
                                if (e.target.value === "" && e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                              {...register(`price${index}`, {
                                required: true,
                                maxLength: 5,
                              })}
                            />
                            {errors?.price?.type === "required" && (
                              <p className="valid-prof">
                                This field is required
                              </p>
                            )}

                            {errors?.price?.type === "maxLength" && (
                              <p className="valid-prof">Limit your charges</p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  {/* {user.sessionNumber?.map(
                    (item, index) => (
                      console.log("item", item.sessions),
                      (
                        <div>
                          <div className="modal-body-div">
                            <input
                              placeholder={item.sessions}
                              defaultValue={item.sessions}
                              onKeyPress={(e) => {
                                if (e.target.value === "" && e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                              {...register("noOfSessions", {
                                required: true,
                                maxLength: 2,
                                pattern: "",
                              })}
                            />
                            {errors?.noOfSessions?.type === "required" && (
                              <p className="valid-prof">
                                This field is required
                              </p>
                            )}

                            {errors?.noOfSessions?.type === "maxLength" && (
                              <p className="valid-prof">Limit your sessions</p>
                            )}
                          </div>
                          <div className="modal-body-div">
                            <input
                              // key={index}
                              placeholder={item.Price}
                              defaultValue={item.Price}
                              onKeyPress={(e) => {
                                if (e.target.value === "" && e.key === " ") {
                                  e.preventDefault();
                                }
                              }}
                              {...register("price", {
                                required: true,
                                maxLength: 5,
                              })}
                            />
                            {errors?.price?.type === "required" && (
                              <p className="valid-prof">
                                This field is required
                              </p>
                            )}

                            {errors?.price?.type === "maxLength" && (
                              <p className="valid-prof">Limit your charges</p>
                            )}
                          </div>
                        </div>
                      )
                    )
                  )} */}
                  <div className="modal-body-div">
                    <div>
                      <p>Session Description</p>
                    </div>
                    <input
                      type="text" // Change type to "text" since you want to allow the plus sign
                      defaultValue={
                        user?.sessionDes?.charAt(0).toUpperCase() +
                        user?.sessionDes?.slice(1)
                      }
                      placeholder="Session Description"
                      {...register("desc", {
                        required: true,
                      })}
                    />
                    {errors?.desc?.type === "required" && (
                      <p className="valid-prof">This field is required</p>
                    )}
                    {/* <select onChange={(e) => handleSkills(e)}> */}
                  </div>
                </div>
                <div className="submit-btn text-center mb-2 mt-2">
                  <button
                    className=" btn btn-submit"
                    // disabled={isDisabled}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </section>
        </Modal.Body>
      </Modal>
      {isPaymentDone ||
        (rejecteRequested && (
          <Modal show={isPaymentDone || rejecteRequested}>
            <Loader />
          </Modal>
        ))}
    </section>
  );
}

export default React.memo(Conformation);

// let info = {
//   bidId: res.data.id,
//   counsellorId: item.counselorid._id,
//   userId: userid._id,
//   sessionId: user._id,
//   sessiontitle: user.sessiontitle,
//   skills: user.skills,
//   sessionDes: user.sessionDes,
//   noOfSessions: item.sessionNumber,
//   sessionDetails: item.sessionSlots,
//   ...details,
//   bidReqId: id,
//   userId: { _id: userid._id },
//   amount: amountToBeAdded,
//   currency: user.defaultCurrency,
//   transactionType: "CREDIT",
//       transactionVia: "Razorpay",
//bidId: id._id,
// bidId: id._id,
// amount: amountToBeAdded,
// userId: bidId: id._id,
// sessionId: emp.state,
// userId: userData.userId,
//       sessionId: emp.state,
//       bidCost: Number(0.03 * user.sessionCost),
//       sessionCurrency: user.defaultCurrency,
//       entity: "counsellor",
// };
