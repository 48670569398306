import React, { useContext, useState, useEffect, useRef } from "react";
import { API } from "../../api-config";
import SectionsHead from "../Home/Heading";
import "../../style/partials/components/_reviews.scss";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAPI } from "../../api/apicounselllor";
import profile from "../../images/test.png";
import { Rating } from "react-simple-star-rating";
import Multiselect from "multiselect-react-dropdown";
import SearchFilter from "./SearchFilter";
import "../../style/partials/components/_Products.scss";
import CS from "../../images/NODEJS.jpg";
import Page from "./Pages";
import UserContext from "../../contexts/UserContext";

let min = null;
let max = null;
let i = 0;
let offset = 1;
let feedOffset = 0;
let wait = false;
const CounsellorResult = () => {
  const navigate = useNavigate();
  const [ratingdata, setRatingdata] = useState([]);
  const [len, setLen] = useState(0);
  const [entries, setEntries] = useState(20);
  const [pages, setPages] = useState(0);
  const [pagesCount, setPagesCount] = useState(0);
  const [priceRange, setPriceRange] = useState("");
  const [time, setTime] = useState("");
  const [rating, setRating] = useState("");
  const [cats, setCats] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [results, setResult] = useState();
  const searchRef = useRef();
  const { users } = useAPI();
  const emp = useLocation();
  const { userData } = useContext(UserContext);
  const [filters, setFilters] = useState([]);
  const [offset, setOffset] = useState(0);

  //Pagination
  useEffect(() => {
    setPagesCount(Math.floor(len / entries));
    console.log("changed length", len, entries);
  }, [len]);
  useEffect(() => {
    console.log("PAGES UPDATED", pages);
  }, [pages]);

  useEffect(() => {
    console.log("Offset Change recorded", offset, filters);
    searchCounsellor(filters);
  }, [offset]);
  const pageChange = (i) => {
    setOffset((prevState) => i);
    console.log("Page Change Recorded", i);
  };
  const pageNum = (num) => {
    console.log("num", num);
    setOffset((prevState) => num);
  };

  const accomodatePage = (clickedPage) => {
    if (clickedPage <= 1) {
      return clickedPage + 1;
    }
    if (clickedPage >= pagesCount) {
      return clickedPage - 1;
    }
    return clickedPage;
  };
  const searchCounsellor = async (selectedFilters) => {
    try {
      setFilters((prevState) => selectedFilters);
      console.log("FILTERED", selectedFilters);
      if (
        !(
          selectedFilters[0]?.length > 0 ||
          selectedFilters[1]?.length > 0 ||
          selectedFilters[2]?.length > 0 ||
          selectedFilters[3]?.length > 0 ||
          selectedFilters[4]?.length > 0
        )
      ) {
        console.log(selectedFilters);
        selectedFilters = [];
        const response = await axios.post(`${API}/api/session/BySearch`, {
          keyword: `${emp.state}`,
          filtered: selectedFilters,
          offset: offset,
          once: false,
        });
        console.log("THIS IS RESPONSE", response.data);
        setResult(response.data.SessiondataFilter);
        setLen(response.data.counts);
      } else {
        console.log(selectedFilters);
        const response = await axios.post(`${API}/api/session/BySearch`, {
          keyword: `${emp.state}`,
          filtered: selectedFilters,
          offset: offset,
          once: false,
        });
        console.log(response.data);
        setResult(response.data.SessiondataFilter);
      }
      // const response = await axios.post(`${API}/api/session/BySearch`, {
      //   keyword: `${emp.state}`,
      //   filtered: selectedFilters,
      //   offset: 0,
      //   once: false,
      // });
      // console.log(response.data);
      // setResult(response.data.SessiondataFilter);
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const filterBySearch = async () => {
    try {
      // console.log("emp.state", emp.state);
      const response = await axios.post(`${API}/api/session/BySearch`, {
        keyword: emp.state,
        filtered: [],
        offset: 0,
        once: true,
      });
      // console.log("ALL RESULT RESPONSE", response.data);
      setResult(response.data.SessiondataFilter);
      // const response = await axios.get(`${API}/api/session/BySearch`, {
      //   params: {
      //     keyword: emp.state,
      //   },
      // });
      setLen((prevState) => response.data.counts);
      console.log("response.data.categFilt", response.data.categFilt);
      if (response.data.categFilt) {
        setCats(response.data.categFilt);
      } else {
        setCats([]);
      }

      // setCats((prevState) => {
      //   let categoriesData = [];
      //   console.log(response.data);
      //   for (
      //     let i = 0;
      //     i < response.data?.categFilt[0]?.categories?.length;
      //     i++
      //   ) {
      //     if (Array.isArray(response.data.categFilt[0].categories[i])) {
      //       categoriesData = [
      //         ...categoriesData,
      //         ...response.data.categFilt[0].categories[i],
      //       ];
      //     } else {
      //       categoriesData.push(response.data.categFilt[0].categories[i]);
      //     }
      //   }
      //   console.log("RESPONSE", response);
      //   return categoriesData;
      // });
      // console.log("ijdfvibhdsfh", response.data.categFilt);
    } catch (error) {
      console.log(error);
    }

    // setCats(() =>
    //   cats.filter((item) =>
    //     response.data.categoryFilter.some((id) => item._id == id._id)
    //   )
    // );
  };

  // useEffect(() => {
  //   try {
  //     ratingData();
  //     // const filteredUsers = users.filter((user) =>
  //     //   user.skills
  //     //     .map((skill) => skill.toUpperCase())
  //     //     .includes(emp.state.toUpperCase())
  //     // );
  //     // setResult(filteredUsers);
  //   } catch (error) {
  //     console.log("ERROR  " + error);
  //   }
  // }, []);
  useEffect(() => {
    filterBySearch();
  }, [emp]);
  return (
    <>
      <div className="container">
        <div className="row">
          <SectionsHead heading="Sessions You Might Like" />
          {cats && (
            <SearchFilter
              handleSearchCounsellor={searchCounsellor}
              categories={cats}
            />
          )}
        </div>

        <div className="container">
          <p className="container-title"></p>
          <div style={{ color: "black", alignItems: "center" }}></div>
          {results &&
            (results.length > 0 ? (
              <div className="gradient-cards row">
                {results &&
                  results.map((items) => (
                    <div className="my-custom-styles col-lg-3 col-md-6 col-sm-8">
                      <div className="session-card-div shadow-sm">
                        <a href="#" className="hero-image-container">
                          <img
                            className="hero-image"
                            src={CS}
                            alt="Spinning glass cube"
                          />
                        </a>
                        <main className="main-content">
                          <div className="d-block">
                            <p className="fw-bold tittel">
                              {items.sessiontitle}
                            </p>
                            <label>Author : </label>
                            <span className="ms-auto">{`${items?.counsellorId?.fName}`}</span>
                          </div>
                          <div className="flex-row skills">
                            <div className="time-left flex-wrap">
                              <label className="fw-bolder">Skills :</label>
                              {items?.skills?.slice(0, 3).map((ele) => (
                                <p>{ele.skill} ,</p>
                              ))}
                            </div>
                          </div>
                          <div className="description">
                            <label>Description : </label>
                            <p className="textdesc">{items?.sessionDes}</p>
                          </div>
                          <div className="flex">
                            <div className="coin-base justify-content-between">
                              <label>Cost : </label>
                              <span>
                                {items.sessionCost +
                                  " " +
                                  items.defaultCurrency}
                              </span>
                              <div className="book-session">
                                <button
                                  className="booking"
                                  onClick={() => {
                                    navigate("/Session-details", {
                                      state: {
                                        id: items._id,
                                        bid: items.sessionNumber,
                                        cost: items.sessionCost,
                                        defaultCurrency: items.defaultCurrency
                                          ? items.defaultCurrency
                                          : "INR",
                                      },
                                    });
                                  }}
                                >
                                  Book Session
                                </button>
                              </div>
                            </div>
                          </div>
                        </main>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <h4>No sessions available in this domain</h4>
            ))}
          {pagesCount > 1 && (
            <div>
              {offset > 1 && (
                <button
                  className="index-btn mb-2 mt-2"
                  onClick={() => {
                    pageNum(offset - 1 >= 1 ? offset - 1 : 1);
                  }}
                >
                  {<i className="fa-solid fa-chevron-left"></i>}
                </button>
              )}
              {(() => {
                const buttons = [];

                if (pagesCount >= 3) {
                  const currentPageNum = accomodatePage(offset);
                  for (let i = -1; i < 2; i++) {
                    buttons.push(
                      <button
                        key={currentPageNum + i}
                        className="index-btn mb-2 mt-2"
                        onClick={() => {
                          pageNum(currentPageNum + i);
                        }}
                      >
                        {currentPageNum + i + 1}
                      </button>
                    );
                  }
                } else {
                  for (let i = 0; i < pagesCount; i++) {
                    buttons.push(
                      <button
                        key={1 + i}
                        className="index-btn mb-2 mt-2"
                        onClick={() => {
                          pageNum(1 + i);
                        }}
                      >
                        {1 + i}
                      </button>
                    );
                  }
                }
                return buttons;
              })()}
              {offset != pagesCount && (
                <button
                  className="index-btn mb-2 mt-2"
                  onClick={() => {
                    pageNum(offset + 1 <= pagesCount ? offset + 1 : pagesCount);
                  }}
                >
                  {<i className="fa-solid fa-chevron-right"></i>}
                </button>
              )}
            </div>
          )}
          {/* {pages && (
            <Page value={{ pages: pages }} onChangePage={pageChange}></Page>
          )} */}
        </div>
      </div>
    </>
  );
};
export default React.memo(CounsellorResult);

{
  /* {results && results ? (
                      results.length > 0 ? (
                        results.map((item, index) => (
                          <div
                            className=" col-lg-4"
                            onClick={() => {
                              navigate("/Session-details", {
                                state: {
                                  id: item._id,
                                  bid: item.sessionNumber,
                                  cost: item.sessionCost,
                                  defaultCurrency: item.defaultCurrency
                                    ? item.defaultCurrency
                                    : "INR",
                                },
                              });
                            }}
                          >
                            <div className="card h-100 shadow">
                              <div className="image-card-div2">
                                <img
                                  src="/static/media/HTML.4a2a02536a16d26260b6.jpg"
                                  className="skills-image w-100"
                                  alt=""
                                />
                              </div>
                              <div className="row">
                                <div className="col-lg-12 profile-diteal">
                                  <img
                                    src={profile}
                                    className=" col-lg-12 card-img prof-img"
                                    alt=""
                                  />

                                  <div className="col-lg-10">
                                    <p>{`${item.sessiontitle} by ${item?.counsellorId[0]?.fName}`}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body">
                                <p className="card-text">
                                  <b>Skills : </b>
                                  {item.skills.map((ele) => `${ele.skill} ,`)}
                                  <br />
                                  {item.sessionDes}
                                </p>
                              </div>
                              <div className="card-footer">
                                <small className="">
                                  {`  Price  :  ${item.sessionCost} `}
                                </small>
                                <Rating initialValue={item.rating} />
                                {/* <i className="fa-solid fa-star"></i>{" "} */
}
{
  /* <span>{item.rating}</span> (
                                <span>{item.ratingCount}</span>)
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <h3>No Counsellors With This Skillset</h3>
                      )
                    ) : (
                      <h3>No Counsellors With This Skillset</h3>
                    )} */
}
