import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  Suspense,
} from "react";
import UserContext from "../../../contexts/UserContext";
import iimmgg from "../../../images/ARTIFICIALINTELLIGENCE.jpg";
import axios from "axios";
import { API } from "../../../api-config";
import { IoLocationSharp } from "react-icons/io5";
import Loader from "../../Loader/Loader";
import "../../../style/partials/Counsellor/_dashboard.scss";
import moment from "moment";
import { Rating } from "react-simple-star-rating";
import Earnings from "./Earnings";
const Counsellor = React.lazy(() => import("../Home/Counsellor"));

const Dash = () => {
  const { userData, setUserData } = useContext(UserContext);
  const [counsellorDetails, setCounsellorDetails] = useState("");
  const [ratingDetails, setRatingDetails] = useState([]);
  const [skills, setSkills] = useState([]);
  const [feedbackDetails, setFeedbackDetails] = useState([]);
  const [wallets, setWallets] = useState([]);
  // console.log("USER DATA DETAILS", userData);
  const dashboardData = async () => {
    const { data } = await axios.post(
      `${API}/api/frontregi/counsellordashboard`,
      { userId: userData.userId }
    );
    console.log("DASHBOARD DETAILS", data);
    setCounsellorDetails(data.response[0].firstDetails[0]);
    setSkills(data.response[0].skillsunwinded[0].skills);
    setRatingDetails(data.response[0].feedbackFacet);
    console.log(data.response[0].firstDetails[0].feedback);
    setFeedbackDetails(data.response[0].firstDetails[0].feedback);

    const res = await axios.get(
      `${API}/api/wallet/get-amounts/${userData.userId}`,
      {
        userId: userData.userId,
        headers: {
          token: userData.token,
        },
      }
    );
    setWallets((prevState) => res.data);
  };
  useEffect(() => {
    if (userData.flag == "COUNSELLOR") {
      dashboardData();
    }
  }, []);
  return (
    <Fragment>
      {counsellorDetails && (
        <div className="container introduction mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3 col-xl-2" style={{ textAlign: "center" }}>
              <img
                src={iimmgg}
                className="dashPic rounded-circle border-success couns-img"
                alt="Profile Pic"
              ></img>
            </div>
            <div className="profileDetails col-lg-9 col-xl-10">
              <p
                style={{
                  color: "#2a578d",
                  fontSize: "22px",
                  textTransform: "capitalize",
                }}
              >
                {counsellorDetails?.fName}
              </p>
              <p>{Math.ceil(counsellorDetails?.ratings[0]?.ratingstar)}</p>
              <p>{`(${counsellorDetails?.ratings[0]?.totalsessions})`}</p>

              <p>
                <IoLocationSharp />
                {counsellorDetails?.counsellor[0]?.country}
              </p>
            </div>
          </div>
          <div className="row mt-0 mt-lg-4">
            <div className="col-md-12 profileDetails">
              <label style={{ fontWeight: "600" }}>About Me :</label>
              <p>{counsellorDetails?.counsellor[0]?.description}</p>
              <label style={{ fontWeight: "600" }}>Skills :</label>
              <div className="d-flex flex-wrap">
                {skills.map((item, index) => (
                  <span className="p-1" key={index}>
                    {item.skill}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {wallets && <Earnings userdetails={wallets} />}
      <Suspense fallback={<Loader />}>
        {userData.isProfile && <Counsellor />}
      </Suspense>
      <div className="protfolio"></div>
      {counsellorDetails && (
        <div className="reviews container ">
          {counsellorDetails?.ratings[0]?.totalsessions > 1 && (
            <p style={{ fontWeight: "600", fontSize: "18px" }}>
              {`${counsellorDetails?.ratings[0]?.totalsessions}   Reviews`}
            </p>
          )}
          {ratingDetails?.map((item, index) => (
            <div className="container" key={index}>
              <div className="row">
                <div className="col-md-6">
                  <div className="well well-sm">
                    <div className="row">
                      <div className="col-xs-12 col-md-6">
                        <div className="row rating-desc">
                          <div className="col-xs-3 col-md-3 ">
                            <span className="fa fa-star"></span>
                            {item._id}
                          </div>
                          <div className="col-xs-8 col-md-9">
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-success"
                                style={{
                                  width: `${
                                    (Number(item.count) /
                                      Number(
                                        counsellorDetails?.ratings[0]
                                          ?.totalsessions
                                      )) *
                                    100
                                  }%`,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span className="col-md-3">{`(${item.count})`}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-3"></div>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="feedbacks container">
        {feedbackDetails &&
          feedbackDetails?.map((item, index) => (
            <div className="d-flex mt-5 mb-5 row">
              <div className="linepart"></div>
              <div className="col-2">
                <img
                  src={iimmgg}
                  style={{
                    height: "160px",
                    borderRadius: "10%",
                  }}
                  alt="reviews"
                  className="col-2 "
                ></img>
              </div>
              <div className="d-flex flex-column col-10">
                <p className="font-weight-bold">{`${item.studentId[0].fName}`}</p>
                <Rating initialValue={item.counsellorRating} />
                <p>{moment(new Date(item.createdAt)).fromNow()}</p>
                <div>{item.feedbackDesc}</div>
              </div>
            </div>
          ))}
      </div>
    </Fragment>
  );
};
export default Dash;
