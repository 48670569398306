import React, { useContext, Suspense } from "react";
import Silder from "../../sliders/Slider";
import CounsellorProfile from "../PopModel/CounsellorProfile";
import Counsellorskills from "./Counsellorskills";
import Loader from "../../Loader/Loader";
import UserContext from "../../../contexts/UserContext";
import Login from "../../form/Login";
import Dash from "../Dashboard/CounsellorDashboard";

function CounsellorHome() {
  const { userData, setUserData } = useContext(UserContext);
  if (
    userData.flag === undefined ||
    userData.flag === "STUDENT" ||
    userData.token === null
  ) {
    return <Login setToken={setUserData} />;
  }

  return (
    <>
      {/* <Silder /> */}
      {!userData.isProfile && <CounsellorProfile />}
      <Suspense fallback={<Loader />}>
        {/* {userData.isProfile && <Counsellor />} */}
        <Dash />
      </Suspense>
      {/* <Counsellorskills /> */}
    </>
  );
}

export default React.memo(CounsellorHome);
