import React from "react";
import usa from "../../images/usa-flag.png";
import india from "../../images/india-flag.png";
import mail from "../../images/email.png";
import "../../style/partials/Counsellor/_contactus.scss";
import Heading from "../Home/Heading";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API } from "../../api-config";

const Contactus = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const submit = () => {
    const name = document.getElementById("user-name").value;
    const email = document.getElementById("email").value;
    const phone = document.getElementById("user-phone").value;
    const message = document.getElementById("message").value;

    axios
      .post(`${API}/api/contactus/createEnquiry`, {
        name: name,
        email: email,
        phone: phone,
        msg: message,
      })
      .then((response) => {
        alert("Query Raised");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="container contact-main-bx mt-5 mb-5">
      <Heading heading="Contact US" />
      <div className="row">
        <div className="col-md-5">
          <div className="contact-bx">
            <i className="fas fa-user"></i>
            <ul className="ps-0 contact-ul ">
              <li className="d-flex alinment-contect-dtl">
                <img src={usa} className=" small icn-holder"></img>
                <a href="tel:+16174305341">+16174305341</a>{" "}
              </li>
              <li className="d-flex alinment-contect-dtl">
                <img src={india} className=" small icn-holder"></img>
                <a href="tel:+16174305341">+16174305341</a>{" "}
              </li>
              <li className="d-flex alinment-contect-dtl">
                <img src={mail} className=" small icn-holder"></img>
                <a href="tel:+16174305341">+16174305341</a>{" "}
              </li>
            </ul>
          </div>
          <div className="contact-bx">
            <i className="fas fa-map-marked-alt"></i>
            <p>
              {" "}
              59, New Rampura Circle Near BSNL Tower Udaipur, Rajasthan 313001
            </p>
          </div>
          <div className="contact-bx">
            <i className="fas fa-clock"></i>
            <ul className="ps-0">
              <li className="d-flex justify-content-center">
                <p className="me-2">Monday to Saturday</p>{" "}
                <p className="time-digitel">9:30 am – 6:30 pm</p>
              </li>
            </ul>
          </div>
        </div>
        <form onSubmit={handleSubmit(submit)} className="col-md-7">
          <div className=" contact-input-box">
            <div className="fv-plugins-icon-container">
              <div className="input-group has-addon-before has-validation">
                <div className="input-group-text">
                  <i className="fas fa-user"></i>
                </div>
                <input
                  id="user-name"
                  name="user-name"
                  type="text"
                  placeholder="Name"
                  required
                  className="form-control fv-group fv-plugins-icon-input"
                  aria-label="Name"
                  {...register("name", {
                    required: true,
                    validate: (value) => value.trim() !== "",
                  })}
                />
                {errors?.name?.type === "required" && (
                  <p style={{ color: "red" }}>Please Enter Name</p>
                )}
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>
              <i data-field="user-name" className="fv-plugins-icon"></i>
            </div>
            <div className="fv-plugins-icon-container mt-3">
              <div className="input-group has-addon-before has-validation">
                <div className="input-group-text">
                  <i className="fa-solid fa-envelope"></i>
                </div>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email Address"
                  required
                  className="form-control fv-group fv-plugins-icon-input"
                  aria-label="Name"
                  {...register("email", {
                    required: true,
                    validate: (value) => value.trim() !== "",
                  })}
                />
                {errors?.email?.type === "required" && (
                  <p style={{ color: "red" }}>Please Enter Email</p>
                )}
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>
              <i data-field="user-name" className="fv-plugins-icon"></i>
            </div>
            <div className="fv-plugins-icon-container mt-3">
              <div className="input-group has-addon-before has-validation">
                <div className="input-group-text">
                  <i className="fa-solid fa-phone"></i>
                </div>
                <input
                  id="user-phone"
                  name="user-phone"
                  type="number"
                  placeholder="Mobile Number"
                  required
                  className="form-control fv-group fv-plugins-icon-input"
                  onKeyDown={(e) => {
                    console.log(e.key, " ,", e.keyCode);
                    if (
                      e.keyCode == 38 ||
                      e.keyCode == 37 ||
                      e.keyCode == 39 ||
                      e.keyCode == 40
                    ) {
                      e.preventDefault();
                    }
                  }}
                  onWheel={(e) => {
                    e.preventDefault();
                  }}
                  aria-label="Name"
                  {...register("phone", {
                    required: true,
                    maxLength: 13,
                    minLength: 10,
                    validate: (value) => value.trim() !== "",
                  })}
                />
                {errors?.phone?.type === "required" && (
                  <p style={{ color: "red" }}>Please enter contact</p>
                )}

                {errors?.phone?.type === "minLength" && (
                  <p style={{ color: "red" }}>Min 10 digits allowed</p>
                )}
                {errors?.phone?.type === "maxLength" && (
                  <p style={{ color: "red" }}>Max 13 digits allowed</p>
                )}
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>
              <i data-field="user-name" className="fv-plugins-icon"></i>
            </div>
            <textarea
              id="message"
              name="message"
              rows="7"
              placeholder="Message"
              required
              className="form-control fv-plugins-icon-input mt-3"
              aria-label="Message"
              {...register("message", {
                required: true,
                validate: (value) => value.trim() !== "",
              })}
            />
            {errors?.message?.type === "required" && (
              <p style={{ color: "red" }}>Please enter query</p>
            )}
            <div className="contact-btn-box">
              <button
                type="submit"
                name="submit-btn"
                value="1"
                className="btn-submit-contact"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contactus;
