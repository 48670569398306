import { React, useState } from "react";
import { Link } from "react-router-dom";
import {
  footMenu,
  footSocial,
  footaApp,
  contactUS,
} from "../../data/FooterItem";
import "../../style/partials/components/_footer.scss";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Footer() {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const currYear = new Date().getFullYear();
  return (
    <div id="footer-wrap">
      <footer className="footer">
        <div className="top-footer container">
          <div className="footer-main-div row">
            {/* <div className="socail-div">
                <div className="col-lg-4 mt-3 d-inline">
                  <h5 className="mt-3">Our Company</h5>
                  <p>
                    Ooze IT Solutions Pvt. Ltd. specializes in providing
                    customized Web Development, App Development & E-commerce
                    solutions with stunning designs to make your websites &
                    applications designed as per your requirements.
                  </p>
                </div>
              </div> */}
            <div className=" col-lg-3 col-md-5 ">
              <div className=" mt-3 d-inline">
                <h5 className="mt-3 mb-4" key="ntr">
                  Useful Links
                </h5>
                <ul className="footer-link usefule-links">
                  {footMenu.map((item, index) => {
                    const { id, path } = item;
                    return (
                      <li key={`${id}li11${index}`}>
                        <i className="fa-solid fa-caret-right"></i>
                        <Link to={path} className="icons" key={`${id}1`}>
                          {item.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" mt-3 d-inline">
                <h5 className="mt-3 mb-4" key="ktr">
                  Contact US
                </h5>
                <ul className="footer-link ">
                  {contactUS.map((item) => {
                    const { id, icon, text, path } = item;
                    return (
                      <li className="icons mb-3" key={`${id}li2`}>
                        <Link className="costmize-icon-footer" to={path}>
                          {icon}
                        </Link>
                        <p>{text}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className=" col-lg-4 col-md-6 mrg-top">
              <div className="mt-3 d-inline">
                <h5 className="mt-3 mb-4" key="ftr">
                  Follow Us
                </h5>
                <ul className="footer-link d-flex">
                  {footSocial.map((item) => {
                    const { id, icon, path } = item;
                    return (
                      <li className="icons " key={`${id}3`}>
                        {" "}
                        <Link to={path}>{icon}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="col-lg-4 mt-3 d-inline  ">
                <h5 className="mt-3 mb-4" key="rtr">
                  Download App
                </h5>
                <ul className="footer-link">
                  {footaApp.map((item) => {
                    const { id, icon, path } = item;
                    return (
                      <li className="icons-social" key={`${id}2`}>
                        {" "}
                        <Link to={path}>{icon}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <div className="row">
            {/* <div className="col-4 botm-menu">
              <Link to="/">
                <p>Terms and Conditions</p>
              </Link>
            </div>
            <div className="col-4 botm-menu">
              <Link to="/">
                <p>Refund Policy</p>
              </Link>
            </div>
            <div className="col-4 botm-menu">
              <Link to="/">
                <p>Privacy Policy</p>
              </Link>
            </div> */}

            <div className="col-md-12">
              <p className="copyright">
                {currYear} Copyright &copy; All Rights Reserved Product of Ooze
                IT Solution PVT. LTD.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
