import React from "react";
import "../../style/partials/components/_heading.scss";

function SectionsHead(props) {
  return (
    <>
      <div className="top-title mt-4 mb-5 ">
        <h2 className="heading faq-page-heading">{props.heading}</h2>
      </div>
    </>
  );
}

export default SectionsHead;
